import React, { useState, useEffect } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";

const listTitles = [
  "Pais",
  "Almacen destino",
  "Almacen origen",
  "Propiedad",
  "Campaña",
  "Moneda",
  "Forma Pago Egresos",
  "Sucursales Egresos",
  "Bancos Egreso",
  "Cajas Egreso",
];
const listDependence = [
  "Cargos",
  "Ciudad",
  "Rubro",
  "Categoria",
  "Subcategoria",
  "Sucursal",
];
const listEditSelects = [
  { rol: "id", typeSelect: "rol" },
  { rol: "idRol", typeSelect: "rol" },
  { state: "idEstado", typeSelect: "state" },
  { empresaProduct: "empresa", typeSelect: "empresaProduct" },
  { empresaCliente: "empresa", typeSelect: "empresaCliente" },
  { estadoCliente: "estado", typeSelect: "estadoCliente" },
  { categoria: "categoria", typeSelect: "categoria" },
  { subcategoria: "subCategoria", typeSelect: "subcategoria" },
  { tipoProducto: "tipoProducto", typeSelect: "tipoProducto" },
  { unidadMedida: "unidadMedida", typeSelect: "unidadMedida" },
  { estado: "estado", typeSelect: "estado" },
  { rubro: "rubro", typeSelect: "rubro" },
  { empresa: "empresa", typeSelect: "empresa" },
  { sucursal: "sucursal", typeSelect: "sucursal" },
  { almacen: "almacen", typeSelect: "almacen" },
  { area: "area", typeSelect: "area" },
  { empleadoAsignado: "empleadoAsignado", typeSelect: "empleadoAsignado" },
  { formaPago: "formaPago", typeSelect: "formaPago" },
  { formaPago: "idFormaPago", typeSelect: "formaPago" },
  { banco: "banco", typeSelect: "banco" },
  { idBanco: "idBanco", typeSelect: "idBanco" },
  { caja: "caja", typeSelect: "caja" },
  { idCaja: "idCaja", typeSelect: "idCaja" },
  { caja: "cajasOther", typeSelect: "cajasOther" },
  { campania: "campania", typeSelect: "campania" },
  { coin: "coin", typeSelect: "coin" },
  { monedaIso: "monedaIso", typeSelect: "monedaIso" },
  { propiedad: "propiedad", typeSelect: "propiedad" },
  { proveedor: "proveedor", typeSelect: "proveedor" },
  { almacenProduct: "almacen", typeSelect: "almacenProduct" },
];
const hasTypeselects = [
  "empresaProduct",
  "empresaClienteFilter",
  "empresaCliente",
  "idEmpresa",
  "almacen",
  "idAlmacen",
  "almacenProduct",
];

const SelectPage = ({
  clearFormFilter = null,
  dependence = "Area",
  errors,
  handleSelect,
  options = [],
  typeSelect,
  title,
  setValue,
  edit = {},
  id = "id",
  descripcion = "descripcion",
  defaultCompanie = null,
  defaultAlmacen = null,
  classNameAux = "mt-10",
}) => {
  const [age, setAge] = useState("");

  useEffect(() => {
    if (Object.keys(edit).length === 0) return;

    listEditSelects.forEach((select) => {
      if (typeSelect === select.typeSelect) {
        let id = edit[select[typeSelect]]?.id || edit[select[typeSelect]];
        id && setAge(id);
      }
    });
  }, [edit]);

  useEffect(() => {
    if (clearFormFilter === "Limpiar") {
      defaultCompanie &&
        setAge(hasTypeselects.includes(typeSelect) ? defaultCompanie : "");
      defaultAlmacen &&
        setAge(hasTypeselects.includes(typeSelect) ? defaultAlmacen : "");
      typeSelect === "idState" && setAge("");
      typeSelect === "estado" && setAge("");
    }
  }, [clearFormFilter, typeSelect]);

  // Selecciona una empresa por defecto.
  useEffect(
    () => defaultCompanie && setAge(defaultCompanie),
    [defaultCompanie]
  );

  // Selecciona un almacen por defecto.
  useEffect(() => defaultAlmacen && setAge(defaultAlmacen), [defaultAlmacen]);

  const handleChange = (event) => {
    handleSelect({ value: event.target.value, typeSelect });
    setAge(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className={`w-full ${listTitles.includes(title) ? "" : classNameAux}`}
      error={errors && !!errors[typeSelect]}
    >
      <InputLabel id="demo-simple-select-filled-label">{title}</InputLabel>
      <Select
        labelId={`demo-simple-select-filled-label-${typeSelect}`}
        id={`demo-simple-select-filled-${typeSelect}`}
        value={age}
        onChange={handleChange}
        variant="outlined"
        label={title}
        title={title}
        className="text-left"
      >
        <MenuItem value="">
          <em>
            {listDependence.includes(title)
              ? `Debe de seleccionar un ${dependence}`
              : "Todos"}
          </em>
        </MenuItem>

        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={title === "Empleado" ? option[descripcion] : option[id]}
          >
            {option[descripcion]}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errors && errors[typeSelect]?.message}</FormHelperText>
    </FormControl>
  );
};

export default React.memo(SelectPage);
