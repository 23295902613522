import React, { useState, useEffect, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { BlockUiContext } from '../../../BlockUiContext';

import ModalSettingPermitPage from '../components/ModalSettingPermitPage';
import { RolesContext } from '../context/RolesContext';
import { defaultValuesFormModalPermissionSetting, schemaFormModalPermissionSetting } from '../utils/helper';
import { PROGRAMS, UPDATE_ROLES, DATA_ROLES_DETAIL } from '../types';
import { callAPIregister } from '../../utils/callAPIS';
import { roles } from '../../../services/roles/roles';

const ModalSettingPermit = ({ dataRolesDetail, isOpenModalPermissionSetting, programsByRol, setIsOpenModalPermissionSetting }) => {

	const dispatch = useDispatch();

	const { handleSubmit, setValue, formState, clearErrors } = useForm({
		defaultValues: defaultValuesFormModalPermissionSetting,
		resolver: yupResolver(schemaFormModalPermissionSetting),
	});

	const { errors } = formState;

	const { stateRoles:{ programs, updateRoles }, dispatchRoles } = useContext( RolesContext );

	const { setIsBlock } = useContext(BlockUiContext);

	const [actions, setActions] = useState([]);
	const [selectedAcciones, setSelectedAcciones] = useState([]);
	const [selectedAccionesDelete, setSelectedAccionesDelete] = useState([]);
	const [selectedAccionesNuevos, setSelectedAccionesNuevos] = useState([]);

	useEffect(() => {

		if (Object.keys(dataRolesDetail).length === 0) return;

		setValue('rol', dataRolesDetail.idPrograma, true);
		
	}, [dataRolesDetail]);

	function onSubmit(model) {

		const isEdit = Object.keys(dataRolesDetail).length > 0;
		const { idRol } = programsByRol;

		!isEdit && (model.acciones = selectedAcciones);
		isEdit && (model.accionesNuevo = selectedAccionesNuevos);
		isEdit && (model.accionesDelete = selectedAccionesDelete);
		isEdit && (model.idPermiso = dataRolesDetail.id);
		model.idRol = idRol;
		
		!isEdit && callAPIregister({service: roles, type: 'registerProgramPermit'}, model, null, dispatchRoles, UPDATE_ROLES, !updateRoles, dispatch, handleCloseModal, setIsBlock);

		isEdit && callAPIregister({service: roles, type: 'updateProgramPermit'}, model, null, dispatchRoles, UPDATE_ROLES, !updateRoles, dispatch, handleCloseModal, setIsBlock);

		setIsBlock(true);
	}

	const handleCloseModal = () => {
		
		setIsOpenModalPermissionSetting(false);

		dispatchRoles({ type: PROGRAMS, payload: [] });
		dispatchRoles({ type: DATA_ROLES_DETAIL, payload: {} });
		setActions([]);
		setSelectedAcciones([]);
		setSelectedAccionesDelete([]);
		setSelectedAccionesNuevos([]);

		clearErrors();
		setValue('rol', '', true);
	}

	const handleSelect = ({value, typeSelect}) => {

		if (value === '') {

			setActions([]);
			return setValue(typeSelect, '', true);
		}

		const findProgram = programs.find(program => program.id === value);

		setActions(findProgram.acciones);
		setValue(typeSelect, value, true);
	}

	const handleChangeCheckBox = (e, data) => {

		const isEdit = Object.keys(dataRolesDetail).length > 0;
		const checked = e.target.checked;

		if (isEdit) {

			const { permisoAcciones } = dataRolesDetail;

			const isHabilitado = permisoAcciones.some(action => (
				(action.idAccion === data.idAccion && !action.habilitado)
			));

			if (checked) {

				const deleteSelectedAcciones = selectedAccionesDelete.filter(action => (
					action.idAccion !== data.idAccion
				));

				setSelectedAccionesDelete(deleteSelectedAcciones);
				isHabilitado && setSelectedAccionesNuevos(prev => [...prev, {idAccion: data.idAccion}]);
			}

			if (!checked) {

				const deleteSelectedAcciones = selectedAccionesNuevos.filter(action => (
					action.idAccion !== data.idAccion
				));

				!isHabilitado && setSelectedAccionesDelete(prev => [...prev, {idAccion: data.idAccion}]);
				setSelectedAccionesNuevos(deleteSelectedAcciones);
			}

		} else {

			checked && setSelectedAcciones(prev => [...prev, {idAccion: data.id}]);

			if (!checked) {

				const deleteSelectedAcciones = selectedAcciones.filter(action => (
					action.idAccion !== data.id
				));

				setSelectedAcciones(deleteSelectedAcciones);
			}
		}

	}
	
	return (
		<ModalSettingPermitPage
			actions={actions}
			dataEdit={dataRolesDetail}
			errors={errors}
			handleSubmit={handleSubmit}
			handleCloseModal={handleCloseModal}
			handleSelect={handleSelect}
			handleChangeCheckBox={handleChangeCheckBox}
			isOpenModal={isOpenModalPermissionSetting}
			onSubmit={onSubmit}
			programs={programs}
			setValue={setValue}
		/>
	)
}

export default ModalSettingPermit;