import {
  OPEN_MODAL,
  OPEN_MODAL_USER,
  UPDATE_COMPONENT,
  DATA_USER,
  CODE_ENCUESTA,
  INFO_ENCUESTA
  /*ROLES,
  STATES,
  OPEN_DIALOG_ALERT,
  UPDATE_COMPONENT,
  PERMITS, */
} from "./types";

export const initialState = {
  isOpenModal: false, //
  isOpenModalUser: false, //
  updateComponent: false, //
  dataUser: {}, //
  codeEncuesta: null,
  dataInfoEncuesta: {}
};

export function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpenModal: payload,
      };

    case OPEN_MODAL_USER:
      return {
        ...state,
        isOpenModalUser: payload,
      };

    case UPDATE_COMPONENT:
      return {
        ...state,
        updateComponent: payload,
      };
    case DATA_USER:
      return {
        ...state,
        dataUser: payload,
      };
    case CODE_ENCUESTA:
      return {
        ...state,
        codeEncuesta: payload,
      };
    case INFO_ENCUESTA:
      return {
        ...state,
        dataInfoEncuesta: payload,
      };
    default:
      return state;
  }
}
