import { showMessage } from "app/store/fuse/messageSlice";
import { locales } from "moment";

export const checkCompaniesByAccess = ({
  companie,
  dispatch,
  TYPE,
  dispatchRedux,
}) => {
  if (companie === -1) {
    dispatch({ type: TYPE, payload: [-1] });

    return dispatchRedux(
      showMessage({
        message: "Esta cuenta no tiene acceso a las empresas", //text or html
        anchorOrigin: {
          vertical: "top", //top bottom
          horizontal: "center", //left center right
        },
        variant: "error", //success error info warning null
      })
    );
  }

  return false;
};

export const alert = (message, dispatch, variant = "warning") =>
  dispatch(
    showMessage({
      message, //text or html
      autoHideDuration: 5000000000000,
      anchorOrigin: {
        vertical: "top", //top bottom
        horizontal: "center", //left center right
      },
      variant, //success error info warning null
    })
  );

export const setDate = (date = "", separator = "/") => {
  return date
    .replace(/[A-Za-z][:0]+/, "")
    .split("-")
    .reverse()
    .join(separator);
};

export const transformTwoDecimals = (number = "") => {
  const numberString = number.toString();
  const decimals = numberString.split(".")[1];
  return decimals && decimals.length > 2
    ? parseFloat(numberString).toFixed(2)
    : numberString;
};

export function formatNumber(number) {
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export const getDateCurrent = () => {
  const arrDate = new Date().toLocaleDateString().split("/");

  const day = arrDate[0].length === 1 ? +0 + arrDate[0] : arrDate[0];
  const month = arrDate[1].length === 1 ? +0 + arrDate[1] : arrDate[1];
  const year = arrDate[2];
  const date = `${year}-${month}-${day}`;

  return date;
};

export const preventEventEnter = (e) => {
  const key = e.key;

  key === "Enter" && e.preventDefault();
};
