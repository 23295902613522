import * as yup from "yup";

export const registerSchema = yup.object().shape({
	al_p8_n1_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p8_n2_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p8_n3_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p8_n4_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p8_n5_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p8_n6_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p8_otro_kg_numero: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p8_n1_cop: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El valor no puede ser negativo"),
	al_p8_n2_cop: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El valor no puede ser negativo"),
	al_p8_n3_cop: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El valor no puede ser negativo"),
	al_p8_n4_cop: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El valor no puede ser negativo"),
	al_p8_n5_cop: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El valor no puede ser negativo"),
	al_p8_n6_cop: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El valor no puede ser negativo"),
	al_p8_otro_cop_numero: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El valor no puede ser negativo"),

	al_p6_n1_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p6_n2_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p6_n3_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p6_n4_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p6_n5_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p6_n6_kg: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
	al_p6_otro_kg_numero: yup
		.number()
		.typeError("El formato debe ser numérico")
		.nullable()
		.transform((_, val) => (val !== "" ? Number(val) : null))
		.moreThan(-1, "El peso no puede ser negativo"),
});
