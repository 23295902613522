import React from 'react';
import { Controller } from 'react-hook-form';
import _ from '@lodash';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';



  
const JWTRegisterTab = ({ control, dirtyFields, errors, handleSubmit, isValid, onSubmit }) => (
	<div className="w-full">
		<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>

			{/* <Controller
				name="displayName"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						className="mb-16"
						type="text"
						label="Display name"
						error={!!errors.displayName}
						helperText={errors?.displayName?.message}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										person
									</Icon>
								</InputAdornment>
							),
						}}
						variant="outlined"
						required
					/> 				
				)}
			/> */}

			<Controller
				name="email"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						className="mb-16"
						type="text"
						error={!!errors.email}
						helperText={errors?.email?.message}
						label="Email"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										email
									</Icon>
								</InputAdornment>
							),
						}}
						variant="outlined"
						required
					/>
				)}
			/>

			<Controller
				name="password"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						className="mb-16"
						type="password"
						label="Password"
						error={!!errors.password}
						helperText={errors?.password?.message}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										vpn_key
									</Icon>
								</InputAdornment>
							),
						}}
						variant="outlined"
						required
					/>
				)}
			/>

			<Controller
				name="passwordConfirm"
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						className="mb-16"
						type="password"
						label="Confirm Password"
						error={!!errors.passwordConfirm}
						helperText={errors?.passwordConfirm?.message}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon className="text-20" color="action">
										vpn_key
									</Icon>
								</InputAdornment>
							),
						}}
						variant="outlined"
						required
					/>
				)}
			/>

			<Button
				type="submit"
				variant="contained"
				color="primary"
				className="w-full mx-auto mt-16"
				aria-label="REGISTER"
				disabled={_.isEmpty(dirtyFields) || !isValid}
				value="legacy"
			>
				Register
			</Button>
		</form>
	</div>
)

export default JWTRegisterTab;