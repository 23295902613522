import * as yup from 'yup';

export const headCells = [
	{ id: 'description', label: 'Descripcion' },
	{ id: 'acciones', label: 'Acciones' },
];

// =====================================
// Formularios
// =====================================

export const defaultValuesFormFilter = {
	description: '',
};

export const defaultValuesFormModalPermissionSetting = {
	rol: '',
};

export const schemaFormModalPermissionSetting = yup.object().shape({
	rol: yup.string()
		.required('El programa es requerido'),
});