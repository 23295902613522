import axios from "axios";
import { getValidationError } from "app/main/utils/validationError";

class Globals {

  updateUser({
    email,
    password,
    nombres,
    ci,
    apellidoPaterno,
    apellidoMaterno,
  }) {
    return new Promise((resolve, reject) => {
      axios
        .put("", {
          email: email || null,
          password: password || null,
          nombres: nombres || null,
          ApellidoPaterno: apellidoPaterno || null,
          ApellidoMaterno: apellidoMaterno || null,
          ci: ci || null,
        })
        .then(({ data }) => {
          !data.error ? resolve(data.message) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get("")
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data);

          !data.error ? resolve(data.data) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }
}

export const globals = new Globals();
