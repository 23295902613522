/**
 * Authorization Roles
 */
// const authRoles = {
//   admin: ['admin'],
//   staff: ['admin', 'staff'],
//   user: ['admin', 'staff', 'user'],
//   onlyGuest: [],
// };

const authRoles = {
  acceso1: [1],
  acceso2: [1, 2],
  acceso3: [1, 2, 3, 4, 5, 6, 7, 8],
  visita: [],
};

export default authRoles;
