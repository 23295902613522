import React from 'react'
import Grid from "@material-ui/core/Grid";
import "../styles/styleFormSuccess.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FormSuccess = ({message}) => (
    <Grid>
    <div className="container_success" >
        <div className="form_container_suc">
        <Grid>
                <h1 className="from_heading_suc font-bold" style={{ color: "#8A133D" }}></h1>
            </Grid>
            <Grid className='mt-24 ml-4'>
                <CheckCircleIcon className='icon-success' color="success"/>
            <span className='text_suc'>{message}</span>
            </Grid>
        </div>
    </div>
</Grid>
)

export default FormSuccess