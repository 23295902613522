import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import history from "@history";
import { submitLogin } from "app/auth/store/loginSlice";
import { submitAuthExterno } from "app/auth/store/loginSlice";
import JWTLoginTabPage from "../components/JWTLoginTabPage";
import { BlockUiContext } from "../../../BlockUiContext";
import jwt_decode from "jwt-decode"
import { setUserData } from "../../../auth/store/userSlice";
import { OPEN_MODAL_USER } from "../types";
import { LoginContext } from "../context/LoginContext";
import {
  schemaLoginUser,
  defaultValuesLoginUser,
} from "../utils/helper";
/**
 * Form Validation Schema 
 */
/* const schema = yup.object().shape({
  username: yup.string().required("Ingrese su usuario"),
  password: yup
    .string()
    .required("Por favor introduce tu contraseña.")
    .min(4, "Mínimo 4 carácteres"),
}); */

const defaultValues = {
  username: "",
  password: "",
};

const JWTLoginTab = ({ isMin600 }) => {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const {
    control,
    setValue,
    formState,
    handleSubmit,
    reset,
    trigger,
    setError,
    register,
    getValues,
    clearErrors
  } = useForm({
    defaultValues: defaultValuesLoginUser,
    resolver: yupResolver(schemaLoginUser),
  });

  const {
    stateLogin: {
      isOpenModalUser,
      dataUser,
      dataInfoEncuesta,
    },
    dispatchLogin,
  } = useContext(LoginContext);

  const { isValid, dirtyFields, errors } = formState;

  const { setIsBlock } = useContext(BlockUiContext);

  const [showPassword, setShowPassword] = useState(false);

  
  useEffect(() => {
    [
      { es: "username", en: "username" },
      { es: "email", en: "username" },
    ].forEach(({ es, en }) => {
      //pasar parametro al caja de texto usuario para logueo
      let username = dataUser["username"];
      let email = dataUser["email"];
      if (username !== "" && username !== undefined) {
        setValue(en, username, true);
      }
      if((username === "" || username === undefined) && email !== undefined){
        setValue(en, email, true);
      }

    });
  }, [dataUser]);

  useEffect(() => {
    login.errors.forEach((error) => {
      setError(error.type, {
        type: "manual",
        message: error.message,
      });
    });
  }, [login.errors, setError]);


  function onSubmit(model) {
    setIsBlock(true);
    dispatch(submitLogin(model, setIsBlock));
  }

  const handleOpenModalUser = () => {
    dispatchLogin({ type: OPEN_MODAL_USER, payload: true });
  };

  const responseGoogle = (response) => {
    var decoded = jwt_decode(response.credential)

    const data = {
      email: decoded.email,
      username: "",
      externalToken: decoded.jti,
      appExternal: "Google",
      nombres: decoded.given_name,
      apellidos: decoded.family_name,
      typeToken: "jwt"
    }

    dispatch(submitAuthExterno(data));
  }

  const responseFacebook = (response) => {
    const data = {
      email: response.data.email,
      username: "",
      externalToken: response.data.accessToken,
      appExternal: "Facebook",
      nombres: response.data.first_name,
      apellidos: response.data.last_name,
      typeToken: "jwt"
    }

    dispatch(submitAuthExterno(data));
  }

  return (
    <JWTLoginTabPage
      control={control}
      dirtyFields={dirtyFields}
      errors={errors}
      handleSubmit={handleSubmit}
      isValid={isValid}
      isMin600={isMin600}
      onSubmit={onSubmit}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      register={register}
      handleOpenModalUser={handleOpenModalUser}
      isOpenModal={isOpenModalUser}
      responseGoogle={responseGoogle}
      responseFacebook={responseFacebook}
      dataUser={dataUser}
      dataInfoEncuesta={dataInfoEncuesta}
    />
  );
};

export default JWTLoginTab;
