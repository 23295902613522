import React from 'react';

import RolesPage from './components/RolesPage';
import ModalPermissions from './container/ModalPermissions';
import RolesProvider from './context/RolesContext';

const Roles = () => {
	
	return (
		<RolesProvider>
			<RolesPage />

			<ModalPermissions />
		</RolesProvider>
	)
}

export default Roles;