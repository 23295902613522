import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import jwtService from "app/services/jwtService";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { hideMessage, showMessage } from "app/store/fuse/messageSlice";
import { setUserData, logoutUser } from "./store/userSlice";
import { debounce } from "@material-ui/core";

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([this.jwtCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on("onAutoLogin", () => {
        /**
         * Sign in and retrieve user data from Api
         
         */
        //recuperando datos de usuario de sessionStorage

        jwtService.setSession(null);
        const userJson = window.sessionStorage.getItem('user');
        if (userJson) {
          const user = JSON.parse(userJson);
          this.props.dispatchSomeAction(user);
          jwtService.setSession(user.Data.Token);
        }

        resolve();
      });

      jwtService.on("onAutoLogout", (message) => {
        if (message) {
          this.props.showMessage({
            message, //text or html
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "warning", //success error info warning null
          });
        }

        this.props.logout();

        resolve();
      });

      jwtService.on("onNoAccessToken", () => {
        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? (
      <FuseSplashScreen />
    ) : (
      <>{this.props.children}</>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
      dispatchSomeAction: setUserData,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
