import { Redirect } from "react-router-dom";
import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import Error404Page from "app/main/404/Error404Page";

import HomeConfig from "app/main/home/HomeConfig";
import ProfileConfig from "app/main/profile/ProfileConfig";
import LoginConfig from "app/main/login/LoginConfig";
import ResetPasswordConfig from "app/main/reset-password/ResetPasswordConfig";
import RolesConfig from "app/main/roles/RolesConfig";
import EncuestasConfig from "app/main/encuestas/EncuestasConfig";
import TerminosCondicionesConfig from "app/main/terminosCondiciones/TerminosCondicionesConfig";

// import SeniorAnalyticConfig from "app/main/seniorAnalytic/SeniorAnalyticConfig";
// import ClientesConfig from "app/main/clientes/ClientesConfig";

const routeConfigs = [
  HomeConfig,
  ProfileConfig,
  LoginConfig,
  ResetPasswordConfig,
  RolesConfig,
  EncuestasConfig,
  TerminosCondicionesConfig,
];

const routes = [ 
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/encuestas" />,
  },

  {
    path: "/login",
    exact: true,
    component: () => <FuseLoading />,
  },

  {
    path: "/terminos-y-condiciones",
    exact: true,
    component: () => <Redirect to="/terminos-y-condiciones" />,
  },

  {
    path: "/404",
    component: () => <Error404Page />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
