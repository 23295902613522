import React from 'react';
import { PulseLoader  } from 'react-spinners';
import "../style.css";

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="spinner-wrapper">
        <PulseLoader color={'#AB2856'} loading={true} />
      </div>
    </div>
  );
}

export default LoadingSpinner;