import React from 'react'
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ReactHtmlParser from 'react-html-parser';

const FormTerminosCondicionesPage = () => {
    return (
        <div>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop={"1.5rem"}
                textAlign="center"
            >
                <Paper
                    variant="outlined"
                    elevation={0}
                    sx={{ width: "100%", marginTop: "2rem", padding: "3rem",}}
                >
                    <span style={{fontSize: "20px", fontWeight: "bold"}}>{ReactHtmlParser(process.env.REACT_APP_TITLE_TERMINOS_CONDICIONES)}</span>
                    <p className='mt-12'>
                    {ReactHtmlParser(process.env.REACT_APP_CONTENIDO_TERMINOS_CONDICIONES)}
                    </p>
                </Paper>
            </Box>

        </div>
    )
}

export default FormTerminosCondicionesPage