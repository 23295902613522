import styled from 'styled-components';

export const ButtonStyle = styled.div`
	button {
		background-color: #66890F;
		
		&:hover {
			background-color: #94AC56;
		}
	}
`;