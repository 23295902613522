import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";
import { Controller } from "react-hook-form";
import { BlockUiContext } from "../../../BlockUiContext";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import { globals } from "../../../services/globals/globals";
import { alert } from "../../../main/utils/helper";
import { useAxios } from "../../../main/customHooks/useAxios";
import EditPassword from "./EditPassword";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #66890F",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50vw",
  },
}));

const EditUser = ({ openFormEditUser, setOpenFormEditUser }) => {
  const { setIsBlock } = useContext(BlockUiContext);

  const classes = useStyles();

  const dispatch = useDispatch();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: "",
      password: "",
      passwordRepeat: "",
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      ci: "",
    },
  });
  const [dataUser, setDataUser] = useState({});
  const profileData = useAxios(globals.getDataUser);

  // Obtener data del usuario
  useEffect(() => {
    const { loading, data } = profileData;

    if (loading) return;

    setDataUser(data);
  }, [profileData]);

  useEffect(() => {
    if (openFormEditUser) return;

    // Limpia los campos del formulario del modal.
    [
      "email",
      "password",
      "passwordRepeat",
      "nombres",
      "apellidoPaterno",
      "apellidoMaterno",
      "ci",
    ].forEach((value) => setValue(value, "", true));
  }, [openFormEditUser]);

  // Pre-cargar los datos
  useEffect(() => {
    if (Object.keys(dataUser).length === 0 || !openFormEditUser) return;

    // cuando el usuario selecciona la funcionalidad editar, rellena los campos.
    [
      { es: "nombres", en: "nombres" },
      { es: "apellidoMaterno", en: "apellidoMaterno" },
      { es: "apellidoPaterno", en: "apellidoPaterno" },
      { es: "email", en: "email" },
      { es: "ci", en: "ci" },
    ].forEach(({ es, en }) => {
      const id = dataUser[es]?.id || dataUser[es];

      setValue(en, id, true);
    });
  }, [dataUser, openFormEditUser]);

  // Modificar datos del usuario
  function onSubmit(model) {
    const { password, passwordRepeat } = model;

    const isPassword = password.length > 0 && passwordRepeat.length === 0;
    const isRepeatPassword = password.length === 0 && passwordRepeat.length > 0;

    if (isPassword || isRepeatPassword) {
      return isPassword
        ? alert("El campo Repetir Contraseña es obligatorio", dispatch)
        : alert("El campo Contraseña es obligatorio", dispatch);
    }

    if (
      password.length > 0 &&
      passwordRepeat.length > 0 &&
      password !== passwordRepeat
    )
      return alert("Los campos tienen que ser iguales", dispatch);

    if (
      (password.length < 4 || passwordRepeat.length < 4) &&
      password.length > 0 &&
      passwordRepeat.length > 0
    )
      return alert("La longitud tiene que ser mayor a 3", dispatch);

    globals
      .updateUser(model)
      .then((resp) => {
        setIsBlock(false);

        dispatch(
          showMessage({
            message: resp, //text or html
            autoHideDuration: 5000000000000,
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
      })
      .catch((err) => {
        setIsBlock(false);

        dispatch(
          showMessage({
            message: err, //text or html
            autoHideDuration: 5000000000000,
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      });

    setOpenFormEditUser(false);
    setIsBlock(true);

    // Limpia los campos del formulario del modal.
    return [
      "email",
      "password",
      "passwordRepeat",
      "nombres",
      "apellidoPaterno",
      "apellidoMaterno",
      "ci",
    ].forEach((value) => setValue(value, "", true));
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openFormEditUser}
      onClose={() => setOpenFormEditUser(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openFormEditUser}>
        <div className={classes.paper}>
          <h3 className="text-center mb-16">Mis datos</h3>

          <p className="mb-10">
            <span className="font-black mr-10">Usuario:</span>
            <span>{dataUser.username}</span>
          </p>

          <form
            noValidate
            autoComplete="off"
            className="flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="nombres"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Nombres"
                  variant="outlined"
                  className="w-full my-10"
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <EditPassword control={control} />

            <Controller
              name="apellidoMaterno"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Apellido materno"
                  variant="outlined"
                  className="w-full my-10"
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name="apellidoPaterno"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Apellido Paterno"
                  variant="outlined"
                  className="w-full my-10"
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Email"
                  variant="outlined"
                  className="w-full my-10"
                  value={value}
                  onChange={onChange}
                  type="email"
                />
              )}
            />

            <Controller
              name="ci"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="CI"
                  variant="outlined"
                  className="w-full my-10"
                  value={value}
                  onChange={onChange}
                  type="number"
                />
              )}
            />

            <div className="flex justify-center">
              <Button
                className="mr-6"
                variant="contained"
                color="primary"
                type="submit"
                style={{ backgroundColor: "#66890F" }}
              >
                Actualizar
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => setOpenFormEditUser(false)}
                style={{ backgroundColor: "#371911" }}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditUser;
