import * as yup from "yup";

// =====================================
// funciones de la tabla
// =====================================


// =====================================
// funciones de los formularios
// =====================================

export const schemaLoginUser = yup.object().shape({
  username: yup
    .string()
    .required("Ingrese su usuario.")
    .min(4, "Debe tener mas de 4 caracteres")
    .max(60, "Máximo permitido 60 caracteres"),
  password: yup
    .string()
    .required("Por favor introduce tu contraseña.")
    .min(4, "Debe tener mas de 4 caracteres")
    .max(60, "Máximo permitido 30 caracteres"),
});

export const defaultValuesLoginUser = {
  username: "",
  email:"",
  password: "",
};

export const schemaFormModalUser = yup.object().shape({
  names: yup
    .string()
    .required("Ingrese sus nombres.")
    .max(60, "Máximo permitido 60 caracteres"),
  username: yup.string().when(['email'], {
    is: (value) => value === "",
    then: yup.string().required("ingrese su username o email"),
  }),
  email: yup.string().email("email no válido"),
  password: yup.string()
      .required("ingrese su contraseña")
      .min(4, "Debe tener mas de 4 caracteres"),
});

export const defaultValuesFormModalUser = {
  names: "",
  username: "",
  email: "",
  password: "",
};
