import React, {
  useReducer,
  useEffect,
  createContext,
  useContext,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { BlockUiContext } from "../../../BlockUiContext";

import { initialState, reducer } from "../reducer";
import {
  STATES,
  DATA_USERS,
  OPEN_MODAL,
  OPEN_MODAL_USER,
  OPEN_DIALOG_ALERT,
  UPDATE_COMPONENT,
  CODE_ENCUESTA,
  INFO_ENCUESTA,
} from "../types";
import { user } from "../../../services/users/user";
import withModal from "../../HOC/withModal";
import { useAxios } from "../../customHooks/useAxios";
import { callAPI, callAPIdelete } from "../../utils/callAPIS";
import { showMessage } from "app/store/fuse/messageSlice";
import JwtService from "../../../services/jwtService";
import { encuesta } from "../../../services/encuestas/encuestas";
import { useGetDataDefault } from "../../customHooks/useGetDataDefault";
import { useLocation } from "react-router-dom";

export const LoginContext = createContext();

const UserProvider = ({ children, handleOpenModalHOC }) => {
  const dispatch = useDispatch();

  const [stateLogin, dispatchLogin] = useReducer(reducer, initialState);
  const { isOpenModal } = stateLogin;

  const [actionModal, setActionModal] = useState(null);
  const { setIsBlock } = useContext(BlockUiContext);

  const searchCode = sessionStorage.getItem("searchCode");

  useEffect(() => {
    if (searchCode == undefined || searchCode == "") return;
    encuesta
      .getEncuestaInfo({ code: searchCode })
      .then((resp) => {
        dispatchLogin({ type: INFO_ENCUESTA, payload: resp });
        setIsBlock(false);
      })
      .catch((err) => {
        setIsBlock(false);
      });
  }, [searchCode]);

  const handleOpenModal = (data, action = null) => {
    setActionModal(action);
    dispatchLogin({ type: OPEN_MODAL, payload: true });
  };

  return (
    <LoginContext.Provider
      value={{
        stateLogin,
        dispatchLogin,
        handleOpenModal,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default withModal(UserProvider);
