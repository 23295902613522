import React, { useState, useEffect, createContext } from "react";

export const BlockUiContext = createContext();

const BlockUiProvider = ({ children }) => {
  const [isBlock, setIsBlock] = useState(false);

  useEffect(() => {
    return () => setIsBlock(false);
  }, []);

  return (
    <BlockUiContext.Provider
      value={{
        isBlock,
        setIsBlock,
      }}
    >
      {children}
    </BlockUiContext.Provider>
  );
};

export default BlockUiProvider;
