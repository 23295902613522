import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Spinner from "../Spinner";
import AlertPage from "../Alert";
import TablePage from "./TablePage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TablePagination = ({
  Component,
  ComponentToPrint,
  data,
  dataFilter,
  dispatch,
  deleteData,
  headCells,
  handleOpenModal,
  handleClickRow,
  page,
  print,
  permits,
  rowsPerPage,
  serviceFilter,
  TYPES,
  typeView,
  titleIcon,
}) => {
  const dispatchRedux = useDispatch();

  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: TYPES[2], // PAGE
      payload: newPage,
    });

    dispatch({
      type: TYPES[0], // DATA
      payload: [],
    });

    dataFilter.page = newPage + 1;
    dataFilter.total = rowsPerPage;

    serviceFilter(dataFilter)
      .then((resolve) =>
        dispatch({
          type: TYPES[0], // DATA
          payload: resolve,
        })
      )
      .catch((err) => {
        dispatchRedux(
          showMessage({
            message: err, //text or html
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: TYPES[1], // ROWS_PER_PAGE,
      payload: parseInt(event.target.value, 10),
    });

    dispatch({
      type: TYPES[2], // PAGE
      payload: 0,
    });

    dispatch({
      type: TYPES[0], // DATA
      payload: [],
    });

    dataFilter.page = 1;
    dataFilter.total = event.target.value;

    serviceFilter(dataFilter)
      .then((resolve) =>
        dispatch({
          type: TYPES[0], // DATA_USERS, DATA_COMPANIES
          payload: resolve,
        })
      )
      .catch((err) => {
        dispatchRedux(
          showMessage({
            message: err, //text or html
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        );
      });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const goBack = () => handleChangePage(null, page - 1);

  return (
    <div className="table__container">
      <span
        className="tab p-14 text-white"
        style={{ backgroundColor: "#24ac7f", fontSize: "15px" }}
      >
        Resultados
      </span>

      {data.length === 0 ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {data[0] === -1 ? (
            <div className="my-10 text-center">
              <AlertPage message="Sin resultados" severity="info" />

              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={goBack}
                disabled={page === 0}
              >
                Volver
              </Button>
            </div>
          ) : (
            <TablePage
              Component={Component}
              ComponentToPrint={ComponentToPrint}
              classes={classes}
              deleteData={deleteData}
              data={data}
              headCells={headCells}
              handleOpenModal={handleOpenModal}
              handleSelectAllClick={handleSelectAllClick}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleClick={handleClick}
              handleClickRow={handleClickRow}
              isSelected={isSelected}
              page={page}
              print={print}
              permits={permits}
              rowsPerPage={rowsPerPage}
              selected={selected}
              typeView={typeView}
              titleIcon={titleIcon}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default TablePagination;
