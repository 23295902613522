import React from 'react';

const ProfilePage = ({ dataUser }) => (
	<div>
		<p className="mb-10">
			<span className="font-black mr-10">Nombre Completo:</span>
			<span>{dataUser.nombreCompleto}</span>
		</p>

		<p className="mb-10">
			<span className="font-black mr-10">Nombres:</span>
			<span>{dataUser.nombres}</span>
		</p>

		<p className="mb-10">
			<span className="font-black mr-10">UserName:</span>
			<span>{dataUser.username}</span>
		</p>

		<p className="mb-10">
			<span className="font-black mr-10">Email:</span>
			<span>{dataUser.email}</span>
		</p>

		<p className="mb-10">
			<span className="font-black mr-10">Apellido paterno:</span>
			<span>{dataUser.apellidoPaterno}</span>
		</p>

		<p className="mb-10">
			<span className="font-black mr-10">Apellido materno:</span>
			<span>{dataUser.apellidoMaterno}</span>
		</p>
	</div>
)

export default ProfilePage;