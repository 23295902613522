import FuseDialog from "@fuse/core/FuseDialog";
import FuseMessage from "@fuse/core/FuseMessage";
import FuseSuspense from "@fuse/core/FuseSuspense";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "app/AppContext";
import SettingsPanel from "app/fuse-layouts/shared-components/SettingsPanel";
import clsx from "clsx";
import React, { memo, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import Toolbar from "./components/Toolbar";
import { MainStyle } from "./style";
import BlockUi from "../../main/layaut/BlockUi";
import { BlockUiContext } from "../../BlockUiContext";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.boxed": {
      clipPath: "inset(0)",
      maxWidth: (props) => `${props.config.containerWidth}px`,
      margin: "0 auto",
      boxShadow:
        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    },
    "&.container": {
      "& .container": {
        maxWidth: (props) => `${props.config.containerWidth}px`,
        width: "100%",
        margin: "0 auto",
      },
    },
  },
}));

const drawerWidth = 280;

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#8A133D",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    top: "69px",
    backgroundColor: "#5f8a86", //#29b08c
  },
  drawerOpenHeight: {
    height: "calc(100vh - 69px)",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    top: "69px",
    backgroundColor: "#5f8a86",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#BFAA6B",
  },
  iconAndText: {
    color: "white",
  },
  doubleArrowleft: {
    transform: "rotateZ(180deg)",
  },
}));

const pathNames = ["/login"];

const title = document.title;

const Layout1 = (props) => {
  const { isBlock } = useContext(BlockUiContext);

  const user = useSelector(({ auth }) => auth.user);
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const classes = useStyles({ ...props, config });
  const classes2 = useStyles2();
  const { pathname } = useLocation();

  // Agregando titulo de forma dinamica
  useEffect(() => {
    document.title = `${title} - ${pathname
      .replace(/[/]/g, "")
      .replace(/-/g, " ")}`;
  }, [pathname]);

  return (
    <div
      id="fuse-layout"
      className={clsx(classes.root, config.mode, "w-full flex")}
    >
      <div className="flex flex-auto min-w-0">
        <BlockUi isBlock={isBlock} />
        <main
          id="fuse-main"
          className="flex flex-col flex-auto min-w-0 relative z-10"
        >
          {config.toolbar.display && (
            <Toolbar classes={classes2} routes={routes} user={user} /> 
          )}
          
          {/*<div className="sticky top-0 z-99">
						<SettingsPanel />
					</div>*/}

          {pathNames.includes(pathname) ? (
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
              {/*<FuseDialog />*/}

              <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

              {/*{props.children}*/}
            </div>
          ) : (
            <MainStyle className="flex flex-col flex-auto min-h-0 relative z-10 mt-20">
              {/*<FuseDialog />*/}

              <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

              {/*{props.children}*/}
            </MainStyle>
          )}
        </main>

        {/*<div className="sticky top-0 z-99">
					<SettingsPanel />
				</div>*/}
      </div>
      <FuseMessage />
    </div>
  );
};

export default Layout1;
