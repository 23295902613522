import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const BrownCheckboxStyle = withStyles({
	root: {
		color: '#371805',
			'&$checked': {
			color: '#371911',
		},
	},
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TablePage = ({ body, editProgram=()=>{}, heads, handleChange=()=>{}, isModalSettingPermit=false, removeProgram=()=>{}, toAccesBody }) => {
	
	return (
		<TableContainer component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						{heads.map((head, index) => (
							<TableCell key={index} align={head.align}>{head.name}</TableCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{
						body.map((data, index) => (
							<TableRow hover key={index}>
								<TableCell
									align="left"
								>{data[toAccesBody]}</TableCell>
								
								{
									isModalSettingPermit ? <TableCell
										align="center"
									>{data.nombre || data.accion}</TableCell>
									: <TableCell
										align="right"
									>
										<Icon
											onClick={() => editProgram(data)}
											className="cursor-pointer"
										>edit_icon</Icon>

										<Icon
											onClick={() => removeProgram(data)}
											className="cursor-pointer"
										>delete_icon</Icon>
									</TableCell>
								}

								{
									isModalSettingPermit
									? <TableCell
										align="right"
									>
										<BrownCheckboxStyle
											onChange={e => handleChange(e, data)}
											defaultChecked={data.habilitado}
										/>
									</TableCell>
									: null
								}
							</TableRow>
						))
					}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default TablePage;