import React, { useState } from "react";
import { useDispatch } from "react-redux";
import history from "@history";

import Toolbar from "@material-ui/core/Toolbar";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { logoutUser } from "app/auth/store/userSlice";

import logo from "../../../assets/images/Tiens-logo.png";
import EditUser from "./EditUser";

const Navbar = ({dataUser}) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openFormEditUser, setOpenFormEditUser] = useState(false);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const signOff = () => dispatch(logoutUser());
  return (
    <React.Fragment>
      <Toolbar>
        <div className="flex flex-1 px-16 items-center">
          {/*    <img src={logo} alt="logo" className="w-2/4 sm:w-96" /> */}
        </div>

        <div className="flex items-center px-8 h-full overflow-x-auto">
          <Icon fontSize="large">person</Icon>

          <span className="mx-14">
            {dataUser ? <p>{`${dataUser.Nombres} ${dataUser.Apellidos === null ? "" : dataUser.Apellidos}`}</p>: null}
            {dataUser ? <p>Usuario: {dataUser.Username? dataUser.Username : dataUser.Email}</p>: null}
          </span>

          <div>
            <Icon
              fontSize="large"
              className="cursor-pointer"
              onClick={handleClick}
            >
              keyboard_arrow_down_icon
            </Icon>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List>
                {/* <ListItem button onClick={() => history.push("/perfil")}>
                  <ListItemIcon>
                    <Icon fontSize="large">person</Icon>
                  </ListItemIcon>

                  <ListItemText primary="Mi cuenta" />
                </ListItem> */}

               {/*  <ListItem button onClick={() => setOpenFormEditUser(true)}>
                  <ListItemIcon>
                    <Icon fontSize="large">fiber_manual_record_icon</Icon>
                  </ListItemIcon>

                  <ListItemText primary="Cambiar contraseña" />
                </ListItem> */}

                <ListItem button onClick={signOff}>
                  <ListItemIcon>
                    <Icon fontSize="large">power_settings_new_icon</Icon>
                  </ListItemIcon>

                  <ListItemText primary="Cerrar sesión" />
                </ListItem>
              </List>
            </Popover>
          </div>
        </div>
      </Toolbar>
      {openFormEditUser ? (
        <EditUser
          openFormEditUser={openFormEditUser}
          setOpenFormEditUser={setOpenFormEditUser}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Navbar;

