import React from "react";
import { showMessage } from "app/store/fuse/messageSlice";

const withSelect = (ComponentNew) => {
  const hoc = class extends React.Component {
    constructor(props) {
      super(props);
    }

    state = {
      positions: [],
    };

    clearHandleSelect = ({ value, dispatch, TYPES }) => {
      value === "" && TYPES.forEach((type) => dispatch({ type, payload: [] }));
    };

    handleSelect = ({ value, typeSelect, service, dispatch, nameSelect }) => {
      if (typeSelect === nameSelect && value !== "") {
        service(value)
          .then((resolve) => this.setState({ positions: resolve }))
          .catch((err) => {
            dispatch(
              showMessage({
                message: err.message || err, //text or html
                anchorOrigin: {
                  vertical: "top", //top bottom
                  horizontal: "center", //left center right
                },
                variant: err.status === 401 ? "warning" : "error", //success error info warning null
              })
            );
          });
      }
    };

    handleSelectproduct = ({
      typeSelect,
      nameSelect,
      value,
      service,
      dispatch,
      TYPE = null,
      dispatchRedux,
      id = null,
      TYPES = [],
      isEdit = false,
    }) => {
      if (typeSelect === nameSelect && value !== "") {
        service(value, id)
          .then((resolve) => dispatch({ type: TYPE, payload: resolve }))
          .catch((err) => {
            dispatchRedux(
              showMessage({
                message: err.message || err, //text or html
                anchorOrigin: {
                  vertical: "top", //top bottom
                  horizontal: "center", //left center right
                },
                variant: err.status === 401 ? "warning" : "error", //success error info warning null
              })
            );
          });
        !isEdit && this.clearHandleSelect({ value: "", dispatch, TYPES });
      }
    };

    render() {
      const { positions } = this.state;

      return (
        <ComponentNew
          {...this.props}
          clearHandleSelect={this.clearHandleSelect}
          handleSelectHOC={this.handleSelect}
          handleSelectproduct={this.handleSelectproduct}
          positions={positions}
        />
      );
    }
  };

  hoc.displayName = `withSelect(${
    ComponentNew.displayName || ComponentNew.name
  })`;

  return hoc;
};

export default withSelect;
