import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { BlockUiContext } from '../../../BlockUiContext';

import { makeStyles } from '@material-ui/core/styles';

import { RolesContext } from '../context/RolesContext';
import ModalPermissionsPage from '../components/ModalPermissionsPage';
import ModalSettingPermit from './ModalSettingPermit';
import { OPEN_MODAL_PERMISSIONS, PROGRAMS_BY_ROL, PROGRAMS, ID_ROL, UPDATE_ROLES, DATA_ROLES_DETAIL } from '../types';
import { roles } from '../../../services/roles/roles';
import { callAPI, callAPIdelete } from '../../utils/callAPIS';
import AlertDialog from '../../layaut/AlertDialog';
// import { changePrice } from '../../../services/changePrice/changePrice';
// import AlertDialog from '../../layaut/AlertDialog';

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},

	heading: {
	    fontSize: theme.typography.pxToRem(15),
	    fontWeight: theme.typography.fontWeightBold,
  	},
  	body: {
  		fontSize: theme.typography.pxToRem(15),
	    fontWeight: theme.typography.fontWeightRegular,
	    marginRight: '25px',
	    marginLeft: '20px',
  	},
  	table: {
    	minWidth: 650,
  	},
}));

const ModalPermissions = () => {

	const classes = useStyles();

	const dispatch = useDispatch();

	const { setIsBlock } = useContext(BlockUiContext);

	const { stateRoles:{ isOpenModalPermissions, programsByRol, dataRolesDetail, updateRoles }, dispatchRoles } = useContext( RolesContext );

	const [isOpenModalPermissionSetting, setIsOpenModalPermissionSetting] = useState(false);
	const [openDialogAlert, setOpenDialogAlert] = useState(false);
	const [dataRemove, setDataRemove] = useState({});

	const handleCloseModal = () => {

		dispatchRoles({ type: PROGRAMS_BY_ROL, payload: [] });
		dispatchRoles({ type: OPEN_MODAL_PERMISSIONS, payload: false });
		dispatchRoles({ type: ID_ROL, payload: null });
	}
	
	const addPermit = () => {

		const { idRol } = programsByRol;

		setIsOpenModalPermissionSetting(true);
		setIsBlock(true);

		callAPI(roles, 'getProgramsByRol', idRol, null, dispatchRoles, PROGRAMS, dispatch, setIsBlock);
	}

	const editProgram = data => {

		const { idRol } = programsByRol;

		callAPI(roles, 'getProgramsDetail', data.id, null, dispatchRoles, DATA_ROLES_DETAIL, dispatch, setIsBlock);

		setIsBlock(true);
		setIsOpenModalPermissionSetting(true);
	}

	const removeProgram = data => {

		if ( Object.keys(dataRemove).length === 0 ) {

			setDataRemove(data);
			return setOpenDialogAlert(true);
		}

		callAPIdelete(roles.deleteProgramPermit, dataRemove.id, dispatchRoles, UPDATE_ROLES, !updateRoles, dispatch, setIsBlock);

		setDataRemove({});
		setIsBlock(true);
	}

	const modalDelete = text => {

		text === 'aceptar' && removeProgram(dataRemove);
		text !== 'aceptar' && setDataRemove({});
		setOpenDialogAlert(false);
	}

	return (
		<React.Fragment>
			<ModalPermissionsPage
				addPermit={addPermit}
				classes={classes}
				editProgram={editProgram}
				handleCloseModal={handleCloseModal}
				isOpenModal={isOpenModalPermissions}
				programsByRol={programsByRol}
				removeProgram={removeProgram}
			/>

			<ModalSettingPermit
				dataRolesDetail={dataRolesDetail}
				isOpenModalPermissionSetting={isOpenModalPermissionSetting}
				programsByRol={programsByRol}
				setIsOpenModalPermissionSetting={setIsOpenModalPermissionSetting}
			/>

			<AlertDialog
				dispatch={dispatchRoles}
				modalDelete={modalDelete}
				open={openDialogAlert}
				title="¿Esta seguro de querer quitar este permiso?"
			/>
		</React.Fragment>
	)
}

export default ModalPermissions;