import React from 'react';

import Icon from '@material-ui/core/Icon';

const CloseModal = ({handleClick}) => (
	<div className="w-full flex justify-end mb-10">
		<Icon
			className="icon cursor-pointer"
			fontSize="large"
			onClick={() => handleClick('cancelar', 'x')}
			style={{color: 'red'}}
		>cancel_icon</Icon>
	</div>
)

export default CloseModal;