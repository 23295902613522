import Profile from './Profile';
import { authRoles } from 'app/auth';

const ProfileConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.acceso3,
  routes: [
    {
      path: '/perfil',
      component: Profile,
    },
  ],
};

export default ProfileConfig;
