export const OPEN_MODAL = "OPEN_MODAL";
export const OPEN_MODAL_USER = "OPEN_MODAL_USER";
export const DATA_USER_EDIT = "DATA_USER_EDIT";
export const DATA_USER = "DATA_USER";
export const ROLES = "ROLES";
export const STATES = "STATES";
export const OPEN_DIALOG_ALERT = "OPEN_DIALOG_ALERT";
export const UPDATE_COMPONENT = "UPDATE_COMPONENT";
export const POSITIONS = "POSITIONS";
export const CODE_ENCUESTA = "CODE_ENCUESTA";
export const INFO_ENCUESTA = "INFO_ENCUESTA";