import React from 'react';
import { Controller } from 'react-hook-form';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import SelectPage from '../../layaut/SelectPage';
import ModalForm from '../../layaut/ModalForm';
import TablePage from './TablePage';

const ModalSettingPermitPage = ({ actions, dataEdit, errors, handleSubmit, handleCloseModal, handleSelect, handleChangeCheckBox, isOpenModal, onSubmit, programs, setValue }) => (
	<ModalForm
		errors={errors}
		handleSubmit={handleSubmit}
		handleCloseModal={handleCloseModal}
		isOpen={isOpenModal}
		onSubmit={onSubmit}
		title='Configuración de Permiso'
		text1={dataEdit.programa ? 'Actualizar' : 'Registrar'}
		text3={dataEdit.programa ? 'Actualizar' : 'Registrar'}
		updateModalHeight={[]}
		updateDataEdit={dataEdit}
	>
		{
			dataEdit.programa ? <p className="mb-10">
				<span className="font-black mr-10">Programa:</span>
				<span>{dataEdit.programa}</span>
			</p>
			: <SelectPage
				errors={errors}
				handleSelect={handleSelect}
				options={programs}
				typeSelect="rol"
				title="Programas"
				setValue={setValue}
				descripcion="nombre"
			/>
		}
		
		<div className="my-16"></div>

		<Typography variant="h6" gutterBottom>
        	Permiso acciones
      	</Typography>

		<TablePage
			body={dataEdit.permisoAcciones ? dataEdit.permisoAcciones : actions}
			heads={[{name: 'ID', align: 'left'}, {name: 'Acciones', align: 'center'}, {name: 'Habilitado', align: 'right'}]}
			handleChange={handleChangeCheckBox}
			isModalSettingPermit={true}
			toAccesBody={dataEdit.permisoAcciones ? 'idAccion' : 'id'}
		/>
	</ModalForm>
)

export default ModalSettingPermitPage;