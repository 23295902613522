import axios from "axios";
import { getValidationError } from "app/main/utils/validationError";

class Roles {
  getPermits() {
    return new Promise((resolve, reject) => {
      axios
        .get("")
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data);

          !data.error ? resolve(data.data) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  getProgramPermissions(idRol) {
    return new Promise((resolve, reject) => {
      axios
        .get(``)
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data);

          !data.error ? resolve(data.data) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  getProgramsByRol(idRol) {
    return new Promise((resolve, reject) => {
      axios
        .get(``)
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data);

          !data.error ? resolve(data.data) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  getProgramsDetail(idPermiso) {
    return new Promise((resolve, reject) => {
      axios
        .get(``)
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data);

          !data.error ? resolve(data.data) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  registerProgramPermit({ rol, acciones, idRol }) {
    return new Promise((resolve, reject) => {
      axios
        .post(``, {
          idPrograma: rol,
          acciones: acciones,
        })
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data[0]);

          !data.error ? resolve(data.message) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  updateProgramPermit({ rol, accionesDelete, accionesNuevo, idPermiso }) {
    return new Promise((resolve, reject) => {
      axios
        .put(``, {
          idPrograma: rol,
          AccionesDelete: accionesDelete,
          AccionesNuevo: accionesNuevo,
        })
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data[0]);

          !data.error ? resolve(data.message) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  deleteProgramPermit(idPermiso) {
    return new Promise((resolve, reject) => {
      axios
        .delete(``)
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data[0]);

          !data.error ? resolve(data.message) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }
}

export const roles = new Roles();
