import FuseUtils from "@fuse/utils/FuseUtils";
import { getValidationError } from "app/main/utils/validationError";
import axios from "axios";
import jwtDecode from "jwt-decode";

/* eslint-disable camelcase */
class JwtService extends FuseUtils.EventEmitter {
  constructor(props) {
    super(props);
    this.state = {
      request: null,
    };
  }

  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.request.use(
      (request) => {
        return request;
      },
      (err) => {
        return err;
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (err.code === "ERR_NETWORK" || err.code === 401) {
          this.handleAuthentication();
          const data = {
            Code: err.code,
            Message: "La sesión ha expirado"
          }

          const response = {
            data: data,
            status: err.code,
          }
          err.response = response;
          return Promise.reject(err);
        }
        throw (err);
      }
    );
  };

  handleAuthentication = async () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.emit("onAutoLogout", "La sesión ha expirado");
      this.logout();
    }
  };

  signInWithUsernameAndPassword = (username, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/usuarios/login", {
          username: username,
          password: password,
        })
        .then(({ data }) => {
          if (!data.error) {
            const { Data: { Token } } = data;

            this.setSession(Token);
            this.setSessionUser(data);
            resolve(data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  signInWithAuthenticationExterno = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/usuarios/login", {
          email: data.email,
          externalToken: data.externalToken,
          appExternal: data.appExternal
        })
        .then(({ data }) => {
          if (!data.error) {

            resolve();
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            this.signInWithRegisterExterno(data)
              .then((success) => {
                resolve(success);
              })
              .catch((error) => {
                this.setSession(null);
                this.emit("onAutoLogout", error.message);
                if (error.response.status === 401)
                  this.emit("onAutoLogout", error.message);
              });
        });
    });
  };

  signInWithRegisterExterno = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/usuarios/registro-externo", {
          externalToken: data.externalToken,
          username: data.username,
          appExternal: data.appExternal,
          nombres: data.nombres,
          apellidos: data.apellidos,
          email: data.email,
          typeToken: data.typeToken
        })
        .then(({ data }) => {
          if (!data.error) {
            this.setSession(data.Data.Token);
            this.setSessionUser(data);
            resolve(data);
          } else {
            return reject(data.message);
          }
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  createUser = (dataUser) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/usuarios/registro", dataUser)
        .then(({ data }) => {
          if (!data.error) {
            resolve(data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      sessionStorage.removeItem("jwt-v");
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  setSessionUser = (user) => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
    } else {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("jwt-v");
    }

  };

  clearSeccionCodeEncuesta = () => {
    sessionStorage.removeItem("searchCode");
    sessionStorage.removeItem("jwt-v");
  }

  logout = () => {
    this.setSession(null);
    this.setSessionUser(null);
    sessionStorage.removeItem("jwt-v");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }

    const decoded = jwtDecode(access_token);

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      sessionStorage.setItem("jwt-v", false);     
      return false;
    }
    sessionStorage.setItem("jwt-v", true); 
    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token") || null;
  };

}

const instance = new JwtService();

export default instance;

