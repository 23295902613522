import React, { useEffect, useReducer, createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { initialState, reducer } from "../reducer";
import {
  DATA_ROLES,
  OPEN_MODAL_PERMISSIONS,
  PROGRAMS_BY_ROL,
  ID_ROL,
  PERMITS,
} from "../types";
import { globals } from "../../../services/globals/globals";
import { roles } from "../../../services/roles/roles";
// import withModal from '../../HOC/withModal';
import { useAxios } from "../../customHooks/useAxios";
import { callAPI } from "../../utils/callAPIS";
import JwtService from "../../../services/jwtService";

export const RolesContext = createContext();

const RolesProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [stateRoles, dispatchRoles] = useReducer(reducer, initialState);

  const { updateRoles, idRol, isOpenModalPermissions } = stateRoles;

  const [actionModal, setActionModal] = useState(null);

  useEffect(() => {
    if (isOpenModalPermissions && actionModal === "roles") {
      JwtService.handleAuthentication();
    }
  }, [isOpenModalPermissions, actionModal]);

  const permits = useAxios(roles.getPermits);
  const rolesData = useAxios(globals.getRoles);

  // Obtener permisos
  useEffect(() => {
    const { loading, data } = permits;

    if (loading) return;

    dispatchRoles({ type: PERMITS, payload: data });
  }, [permits]);

  // Obtener roles
  useEffect(() => {
    const { loading, data } = rolesData;

    if (loading) return;

    dispatchRoles({ type: DATA_ROLES, payload: data });
  }, [rolesData]);

  // Obtener programas y permisos cada vez que se registra uno
  useEffect(() => {
    if (!idRol) return;

    callAPI(
      roles,
      "getProgramPermissions",
      idRol,
      null,
      dispatchRoles,
      PROGRAMS_BY_ROL,
      dispatch
    );
  }, [updateRoles, idRol]);

  const handleOpenModal = (data, typeIcon) => {
    if (typeIcon === "roles") {
      setActionModal(typeIcon);
      const { id, descripcion } = data;

      dispatchRoles({ type: ID_ROL, payload: id });

      return dispatchRoles({ type: OPEN_MODAL_PERMISSIONS, payload: true });
    }
  };

  return (
    <RolesContext.Provider
      value={{
        stateRoles,
        dispatchRoles,
        handleOpenModal,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export default RolesProvider;
