import React, { useState, memo } from 'react';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';

import Navbar from './Navbar';
//import OptionMenu from './OptionMenu';

const Toolbar = ({ classes, routes, user }) => {

	//const [open, setOpen] = useState(false);
	return (
		<div className={classes.root}>
			<CssBaseline />

			<AppBar 
				position="fixed"
				className={classes.appBar}
			>
				<Navbar dataUser={user.Data} />	 	
			</AppBar>
 
			{/* <OptionMenu 
				classes={classes}
				modulos={user.modulos}
				open={open}
				setOpen={setOpen}
			/> */}
		</div>
	)
}

export default memo(Toolbar);