import React from 'react'
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormHelperText } from '@material-ui/core';

const RadioGroupCheck = ({
    register,
    question,
    handleChange,
    addToRefsRb,
    addToRefsQuestions,
    checkedRB
}) => {
    return (
        <div>
            <RadioGroup            
                ref={addToRefsQuestions}
                aria-labelledby="demo-radio-buttons-group-label"
                id={question.code}
                name={question.code}
                className="radios"
                defaultValue={question?.response ? 
                    question?.response?.opciones?.find(opc => opc.keyResponse !== "").keyResponse
                     : null}
            >
                <FormControl sx={{ m: 3 }} variant="standard">
                    {
                        question?.options?.map(option =>
                            <FormControlLabel
                                key={option.key}
                                value={option.key}                              
                                control={<Radio
                                    id={option.key}                                  
                                    onChange={(e) => { handleChange(e, question, option, null, null) }}
                                    defaultChecked={checkedRB}
                                    />}
                                label={<span style={{ fontSize: 15 }}>{option?.value}</span>}
                                ref={addToRefsRb}                         
                            />
                        )
                    }
                </FormControl>
            </RadioGroup>
        </div>
    )
}

export default RadioGroupCheck