import styled from "styled-components";

export const AlertDialogStyle = styled.section`
  background-color: #24ac7f;
  width: 380px;

  @media (max-width: 445px) {
    width: 300px;
  }

  @media (max-width: 365px) {
    width: 255px;
  }
`;

export const ButtonsgStyle = styled.section`
  transition: 0.5s background-color;

  .button-green {
    background-color: #66890f;

    &:hover {
      background-color: #94ac56;
    }
  }

  .button-brown {
    background-color: ${({ colorCancel }) => colorCancel};

    &:hover {
      background-color: #735e58;
    }
  }

  @media (max-width: 377px) {
    text-align: center;

    button {
      width: 100%;
      margin-top: 10px;
    }
  }
`;

export const ModalStyle = styled.div`
  width: 550px;

  &:focus-visible {
    outline: none;
  }

  .tab {
    background-color: white;
    border-radius: 20px 20px 0 0px;
  }

  .pb-0-important {
    padding-bottom: 0 !important;
  }

  ${({ isScroll }) => `
		overflow-y: ${isScroll ? "scroll" : ""};
		height: ${isScroll ? "100vh" : ""};
	`}

  ${({ nameUrl, isModalView, title }) => {
    if (nameUrl === "usuarios")
      return `
			@media (min-width: 1440px) {
				width: 650px;
			}
		`;

    if (nameUrl === "productos" && !isModalView)
      return `

			@media (min-width: 955px) {
				width: 650px;
			}

			@media (min-width: 1250px) {
				width: 750px;
			}

			@media (min-width: 1440px) {
				width: 850px;
			}

			@media (min-width: 2140px) {
				width: 950px;
			}
		`;

    if (nameUrl === "empresas")
      return `
			
			@media (max-width: 405px) {
				width: 350px;

				.tab {
					padding: .9rem;
					font-size: 1rem;
				}
			}

			@media (min-width: 700px) {
				width: 70vw;
			}

			@media (min-width: 1300px) {
				width: 50vw;
			}
		`;

    if (nameUrl === "adminrecetas")
      return `		
			width: 650px;
		`;

    if (nameUrl === "ordenesdeproducción" && title === "Crear empleado")
      return `		
			width: 60vw;
		`;

    if (nameUrl === "ordenesdeproducción" && title !== "Crear Gasto")
      return `		
			width: 90vw;
		`;

    if (nameUrl === "inventarios" && title === "Transferir inventario")
      return `
			width: 75vw;

			@media (max-width: 800px) {
				width: 80vw;
			}
		`;

    if (nameUrl === "inventarios" && title !== "Ajuste Inventario")
      return `
			
			@media (min-width: 900px) {
				width: 70vw;

				.table-scroll {
					justify-content: center;
				}
			}
		`;

    if (title === "mi caja")
      return `
			width: 70vw;

			@media (max-width: 850px) {
				width: 80vw;
			}

			@media (max-width: 760px) {
				width: 90vw;
			}
		`;

    if (
      (nameUrl === "compras" &&
        title !== "Crear proveedor" &&
        title !== "Nuevo producto") ||
      (nameUrl === "ventasdeldía" &&
        title !== "Agregar cliente" &&
        title !== "Mi caja" &&
        title !== "factura") ||
      (nameUrl === "ventas" &&
        title !== "Nuevo Cliente" &&
        title !== "Imprimir Comprobante")
    )
      return `
			width: 90vw;

			@media (min-width: 1200px) {
				width: 85vw;
			}

			@media (min-width: 1600px) {
				width: 75vw;
			}

			@media (min-width: 1900px) {
				width: 65vw;
			}

			@media (min-width: 2200px) {
				width: 55vw;
			}

			@media (min-width: 2500px) {
				width: 50vw;
			}
		`;

    if (nameUrl === "cuentasporpagar" && title !== "Agregar Pago")
      return `
			width: 90vw;

			@media (min-width: 992px) {
				width: 80vw;
			}

			@media (min-width: 1200px) {
				width: 70vw;
			}

			@media (min-width: 1600px) {
				width: 50vw;
			}

			@media (min-width: 2300px) {
				width: 45vw;
			}
		`;

    if (
      nameUrl === "cambiodeprecios" ||
      (nameUrl === "cuentasporcobrar" && title !== "Agregar Pago")
    )
      return `
			width: 80vw;
		`;

    if (nameUrl === "roles")
      return `
			width: 90vw;

			@media (min-width: 800px) {
				width: 75vw;
			}

			@media (min-width: 1030px) {
				width: 65vw;
			}

			@media (min-width: 1024px) {
				width: 50vw;
			}

			@media (min-width: 2200px) {
				width: 40vw;
			}
		`;

    if (
      (nameUrl === "egresos" || nameUrl === "ingresos") &&
      title !== "Crear Persona"
    )
      return `
			width: 90vw;
		`;
  }}
`;

export const AutoCompeteStyles = styled.div`
  background-color: white;
  opacity: 0.99;
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 100%;
  box-shadow: 4px 4px 6px rgba(50, 50, 50);
  text-align: left;
  overflow-x: ${({ isOverflow }) => (isOverflow ? "scroll" : "visible")};

  .search__text {
    cursor: pointer;
    transition: background-color 0.1s;
    padding: 10px 20px;
    font-size: 1.5rem;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const DropdownTreeSelectStyles = styled.div`
  background-color: white;
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  box-shadow: 4px 4px 6px rgba(50, 50, 50);
  overflow-x: ${({ isOverflow }) => (isOverflow ? "scroll" : "visible")};
`;

export const PopperStyle = styled.div`
  .image {
    width: 500px;
    height: 600px;
  }

  @media (max-width: ${({ isLeft }) => (isLeft ? "704px" : "652px")}) {
    .image {
      width: 400px;
    }
  }

  @media (max-width: ${({ isLeft }) => (isLeft ? "600px" : "552px")}) {
    .image {
      width: ${({ isLeft }) => (isLeft ? "250px" : "350px")};
      height: ${({ isLeft }) => (isLeft ? "300px" : "400px")};
    }
  }

  @media (max-width: 502px) {
    .image {
      width: 300px;
      height: 400px;
    }
  }

  @media (max-width: 402px) {
    .image {
      width: 250px;
      height: 450px;
    }
  }

  @media (max-width: 352px) {
    .image {
      width: 200px;
      height: 400px;
    }
  }
`;
