import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";

export const useGetDataDefault = ({
  service,
  typeService,
  dispatch,
  TYPE,
  code,
}) => {
  const dispatchRedux = useDispatch(); 

  useEffect(() => {
    service[typeService]({ code })
      .then((resp) => dispatch({ type: TYPE, payload: resp }))
      .catch((err) => {
        dispatchRedux(
          showMessage({
            message: err.response.data.Data.Message || err, //text or html
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: err.response.status === 401 ? "warning" : "error", //success error info warning null
          })
        );

          if(err.response.status === 404){
            sessionStorage.removeItem("searchCode");
          }

        dispatch({
          type: TYPE,
          payload: err.response.data,
        });
      });
  }, []);

  return null;
};
