import {
  STATES,
  DATA_ENCUESTAS,
  PAGE,
  ROWS_PER_PAGE,
  OPEN_MODAL,
  OPEN_MODALVIEW,
  OPEN_DIALOG_ALERT,
  DATA_ENCUESTA_EDIT,
  UPDATE_COMPONENT,
  SUCCESS,
  ERROR,
  LISTA_ERRORS
} from "./types"; 

export const initialState = {
  states: [],
  dataEncuestas: {},
  isOpenModal: false,
  isSuccess: false,
  isError: false,
  updateComponent: false,
  listaErros: [],
};

export function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case STATES:
      return {
        ...state,
        states: payload,
      };

    case DATA_ENCUESTAS:
      return {
        ...state,
        dataEncuestas: payload,
      };

    case OPEN_MODAL:
      return {
        ...state,
        isOpenModal: payload,
      };

    case UPDATE_COMPONENT:
      return {
        ...state,
        updateComponent: payload,
      };
    case SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      };
    case ERROR:
      return {
        ...state,
        isError: payload,
      };
    case LISTA_ERRORS:
      return {
        ...state,
        listaErros: payload,
      };

    default:
      return state;
  }
}
