import Encuestas from './Encuestas';
import { authRoles } from 'app/auth';

const EncuestasConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.acceso3,
  routes: [
    {
      path: '/encuestas',
      component: Encuestas,
    },
  ],
};

export default EncuestasConfig;