import React, { useState, useEffect } from "react";

import { useAxios } from "../customHooks/useAxios";
import { globals } from "../../services/globals/globals";
import ProfilePage from "./ProfilePage";

const Profile = () => {
  const profileData = useAxios(globals.getDataUser);

  const [dataUser, setDataUser] = useState({});

  // Obtener data del usuario
  useEffect(() => {
    const { loading, data } = profileData;

    if (loading) return;

    setDataUser(data);
  }, [profileData]);

  return <ProfilePage dataUser={dataUser} />;
};

export default Profile;
