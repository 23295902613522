import React from "react";
import { Controller } from "react-hook-form";
import _ from "@lodash";
import history from "@history";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode"
import { Link } from "react-router-dom";
import loginImage from "../../../assets/images/login.jpg";
import googleImage from "../../../assets/images/google.png";
import { FacebookLoginButton } from "react-social-login-buttons";
import { LoginSocialFacebook } from "reactjs-social-login";
import ReactHtmlParser from 'react-html-parser';
import "./stylelogin.css";

const JWTLoginTabPage = ({
  control,
  errors,
  handleSubmit,
  onSubmit,
  showPassword,
  setShowPassword,
  handleOpenModalUser,
  responseGoogle,
  responseFacebook,
  dataInfoEncuesta,
}) => (
  <Grid>
    <div className="containerLogin">
      <div className="form_container">
        <div className="right">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="from_heading font-bold" style={{ color: "#8A133D" }}>
              {
                dataInfoEncuesta?.data?.title ?
                ReactHtmlParser(dataInfoEncuesta?.data.title) :
                ReactHtmlParser(process.env.REACT_APP_CONFIG_TITLE)
              
              }</h1>
            <div className="container-grid">
              <Grid>
                <Controller
                  name="username"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type="text"
                      fullWidth
                      error={!!errors.username}
                      helperText={errors?.username?.message}
                      label="Usuario (*)"
                      value={value}
                      onChange={onChange}
                      InputLabelProps={{
                        style: { fontSize: 15 },
                      }}
                      InputProps={{
                        style: { fontSize: 15, width: "320px", backgroundColor: "#ffff" },
                      }}
                      variant="filled"
                      size="small"
                    />
                  )}

                />
              </Grid>
              <br />
              <Grid>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-16"
                      label="Contraseña (*)"
                      type="password"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="filled"
                      size="small"
                      InputLabelProps={{
                        style: { fontSize: 15 },
                      }}
                      InputProps={{
                        type: showPassword ? "text" : "password",
                        style: { fontSize: 15, width: "320px", backgroundColor: "#ffff" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Icon className="text-20" color="action">
                                {showPassword ? <Visibility className="mb-3" /> : <VisibilityOff className="mb-3" />}
                              </Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

              </Grid>

              <Grid>
                <Button
                  type="submit"
                  variant="contained"
                  //color="primary"
                  aria-label="LOG IN"
                  className="btn"
                  value="legacy"
                  size="large"
                >
                  INGRESAR
                </Button>
                <div className="containerRegistrarme">
                  <p className="textCuenta">¿Tienes cuenta?</p>
                  <Typography
                    className="tipography"
                    //variant='h6'
                    onClick={handleOpenModalUser}
                  >
                    Registrarme
                  </Typography>
                </div>

              </Grid>
            </div>
          </form>
          <div className="lineaSeparador"></div>
          <p className="textCuenta">Iniciar sesion con</p>
          <Grid>
            <GoogleOAuthProvider clientId="664102857392-8deeqtvmspnvusv029ob8f07gbbk63fo.apps.googleusercontent.com">
              <GoogleLogin
                buttonText="Iniciar con Google"
                onSuccess={responseGoogle}
              ></GoogleLogin>
            </GoogleOAuthProvider>
          </Grid>

          <Grid className="mt-2">
            <LoginSocialFacebook
              appId="145297411906310"
              onResolve={responseFacebook}
            >
              <FacebookLoginButton>
                <span>Iniciar con Facebook</span>
              </FacebookLoginButton>
            </LoginSocialFacebook>

          </Grid>
        </div>
      </div>
    </div>
  </Grid>


);

export default JWTLoginTabPage;
