import React from 'react';
import Spinner from './Spinner';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const BlockUi = ({ isBlock }) => (
	<React.Fragment>
		{
			isBlock ? <div className="flex items-center h-screen w-full fixed bg-white bg-opacity-50" style={{zIndex: 1400}}>
				<Spinner />
			</div> : null
		}
	</React.Fragment>
)

export default BlockUi;