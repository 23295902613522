import React from "react";

import { ButtonsgStyle } from "./style";

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

const arrIsButton = ["Limpiar", "Cancelar", "Salir"];

const TwoButtons = ({
  text1 = "",
  text2 = "",
  classes1,
  classes2 = "",
  icon1 = "",
  icon2 = "",
  handleClick = () => {},
  handleButton = () => {},
  colorCancel = "#371911",
  text3 = "Guardar",
  text4 = "",
  argument1 = null,
  argument2 = null,
  action,
}) => (
  <ButtonsgStyle colorCancel={colorCancel}>
    <Button
      type="submit"
      variant="contained"
      title={text1}
      style={{ backgroundColor: "#5f8a86" }}
      className={`${classes1} mb-10 ${text1 === "" ? "hidden" : ""}`}
      color="primary"
      value="legacy"
      size="large"
      onClick={
        text1 === text3
          ? () => handleButton(argument1)
          : () => handleClick(text1, action)
      }
      endIcon={<Icon>{icon1}</Icon>}
    >
      {text1}
    </Button>

    {text2 ? (
      <Button
        type={arrIsButton.includes(text2) ? "button" : "submit"}
        variant="contained"
        title={text2}
        className={`${classes2} mb-10 button-brown`}
        color="primary"
        value="legacy"
        size="large"
        onClick={
          text2 === text4
            ? () => handleButton(argument2)
            : () => handleClick(text2)
        }
        endIcon={icon2 === "" ? "" : <Icon>{icon2}</Icon>}
      >
        {text2}
      </Button>
    ) : null}
  </ButtonsgStyle>
);

export default TwoButtons;
