import React, {
  useState,
  useReducer,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import { BlockUiContext } from "../../../BlockUiContext";
import { useLocation } from "react-router-dom";
import { initialState, reducer } from "../reducer";
import {
  DATA_ENCUESTAS,
  OPEN_MODAL,
  UPDATE_COMPONENT,
} from "../types";
import { encuesta } from "../../../services/encuestas/encuestas";
import withModal from "../../HOC/withModal";
import { useAxios } from "../../customHooks/useAxios";
import { useGetDataDefault } from "../../customHooks/useGetDataDefault";
import { callAPI, callAPIdelete } from "../../utils/callAPIS";
import { saveCodeEncuesta } from "../../../auth/store/loginSlice";
export const EncuestasContext = createContext();

const EncuestasProvider = ({ children, handleOpenModalViewHOC }) => {
  const dispatch = useDispatch();

  const [stateEncuestas, dispatchEncuestas] = useReducer(reducer, initialState);
  /*  const [stateFormInt, setStateFormInt] = useState(false);
   const [stateFormAgr, setStateFormAgr] = useState(false); */

  const {
    dataEncuestas,
    page,
    rowsPerPage,
    updateComponent,
  } = stateEncuestas;
  const { search } = useLocation();
  const { setIsBlock } = useContext(BlockUiContext);

  const [stateError, setStateError] = useState(false);
  const [stateSuccess, setStateSuccess] = useState(false);
  
  const searchCode = search !== "" ? search : sessionStorage.getItem("searchCode");

  useGetDataDefault({
    service: encuesta,
    typeService: searchCode !== null ? "getEncuestaLink" : "getEncuesta",
    dispatch: dispatchEncuestas,
    TYPE: DATA_ENCUESTAS,
    code: searchCode ? searchCode : null,
  });

  useEffect(() => {
    if(updateComponent === false) return;
    
    const obj = {
      code : searchCode,
    };

    callAPI(
      encuesta,
      "getEncuestaLink",
      obj,
      null,
      dispatchEncuestas,
      DATA_ENCUESTAS,
      dispatch
    );
  }, [updateComponent]);

  return (
    <EncuestasContext.Provider
      value={{
        stateEncuestas,
        dispatchEncuestas,
        /* stateFormInt,
        stateFormAgr,
        onSubmitBtnA,
        onSubmitBtnB, */
        stateError,
        stateSuccess,
        setStateError,
        setStateSuccess,
      }}
    >
      {children}
    </EncuestasContext.Provider>
  );
};

export default withModal(EncuestasProvider);
