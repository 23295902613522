import { authRoles } from 'app/auth';
import Roles from './Roles';

const RolesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.acceso3,
  routes: [
    {
      path: '/roles',
      component: Roles,
    },
  ],
};

export default RolesConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
