import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStylesFacebook = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	bottom: {
		color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	top: {
		color: '#1a90ff',
		animationDuration: '550ms',
		position: 'absolute',
		left: 'calc(50% - 20px)',
	},
	circle: {
		strokeLinecap: 'round',
	},
}));

const FacebookCircularProgress = (props) => {

	const classes = useStylesFacebook();
	
	return (
		<div className={classes.root}>
			<CircularProgress
				variant="determinate"
				className={classes.bottom}
				size={40}
				thickness={4}
				{...props}
				value={100}
			/>

			<CircularProgress
				variant="indeterminate"
				disableShrink
				className={classes.top}
				classes={{
				circle: classes.circle,
				}}
				size={40}
				thickness={4}
				{...props}
			/>
		</div>
	)
}

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		marginTop: '25px',
		textAlign: 'center',
	},
});

function Spinner() {
	
	const classes = useStyles();

	return (
		<div className={`${classes.root} z-50`}>
			<FacebookCircularProgress />
			<br />
		</div>
	);
}

export default Spinner;