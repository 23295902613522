import React, {
    useState,
    useReducer,
    useEffect,
    createContext,
    useContext,
  } from "react";
  import withModal from "../../HOC/withModal";
export const TerminosCondicionesContext = createContext();
const TerminosCondicionesProvider = ({ children, handleOpenModalViewHOC }) => {
    
    return (
      <TerminosCondicionesContext.Provider
        value={{

        }}
      >
        {children}
      </TerminosCondicionesContext.Provider>
    );
  };
  
  export default withModal(TerminosCondicionesProvider);