import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import Checkbox from '@material-ui/core/Checkbox';
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";

import Print from "../Print";
import EnhancedTableHead from "./EnhancedTableHead";
import Invoice from "../../../assets/images/cuenta.png";
import { formatNumber } from "../../utils/helper";

const hiddenEdits = [
  "ordenes-de-produccion",
  //"inventarios",
  "cuentas-por-pagar",
  "cambio-precios",
  "ventas-del-día",
  "ventas",
  "cuentas-por-cobrar",
  "roles",
  "empresas",
  "analítico-de-mayores",
];
const hiddenDeletes = [
  "empresas",
  "ordenes-de-produccion",
  "inventarios",
  "compras",
  "ventas",
  "cuentas-por-pagar",
  "cambio-precios",
  "ventas-del-día",
  "cuentas-por-cobrar",
  "roles",
  "egresos",
  "ingresos",
  "analítico-de-mayores",
];
const hiddenViews = [
  "usuarios",
  "campañas",
  "ordenes-de-produccion",
  "inventarios",
  "cambio-precios",
  "ventas-del-día",
  "roles",
  "egresos",
  "ingresos",
  "analítico-de-mayores",
];
const hiddenChecks = [
  "usuarios",
  "campañas",
  "empresas",
  "productos",
  "recetas",
  "inventarios",
  "ventas",
  "compras",
  "ventas-del-día",
  "roles",
  "egresos",
  "ingresos",
  "analítico-de-mayores",
  "clientes",
];
const hiddenPrint = [
  "usuarios",
  "empresas",
  "campañas",
  "productos",
  "recetas",
  "inventarios",
  "cuentas-por-pagar",
  "cambio-precios",
  "cuentas-por-cobrar",
  "roles",
  "egresos",
  "ingresos",
  "analítico-de-mayores",
  "clientes",
  "compras",
];
const viewDeleteX = ["ventas", "compras"];
const isCancel = ["Anulado", "Cancelado"];
const isPermitsPrint = ["ventas-del-día"];

const TablePage = ({
  Component,
  ComponentToPrint,
  classes,
  deleteData,
  data = [],
  headCells,
  handleOpenModal,
  handleSelectAllClick,
  handleChangePage,
  handleChangeRowsPerPage,
  handleClick,
  handleClickRow,
  isSelected,
  isPaginationService = true,
  page,
  print,
  permits = {},
  rowsPerPage,
  selected,
  typeView,
  titleIcon,
}) => (
  <div className={classes.root}>
    <Paper className={classes.paper}>
      <TableContainer style={{ maxHeight: "440px" }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="sticky table"
          stickyHeader
        >
          <EnhancedTableHead
            headCells={headCells}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data.length}
            style={{
              backgroundColor: "#5f8a86",
            }}
          />
          <TableBody>
            {data.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow hover tabIndex={-1} key={index}>
                  <Component
                    labelId={labelId}
                    row={row}
                    handleClickRow={handleClickRow || handleOpenModal}
                    permits={permits}
                  />

                  <TableCell align="center">
                    <div className="flex items-center justify-center w-full">
                      {hiddenChecks.includes(typeView) ||
                      isCancel.includes(row.estado) ||
                      permits?.search === false ? null : (
                        <Icon
                          onClick={() => handleOpenModal(row)}
                          className="cursor-pointer"
                          title={titleIcon}
                        >
                          assignment_turned_in_icon
                        </Icon>
                      )}

                      {hiddenEdits.includes(typeView) ||
                      row.estado === "Anulado" ||
                      row.estado === "Inventariado" ||
                      permits?.update === false ? null : (
                        <Icon
                          title="Editar"
                          onClick={() => handleOpenModal(row, "edit")}
                          className="cursor-pointer"
                          style={{ color: "#186A3B", fontSize: "27px" }}
                        >
                          edit_icon
                        </Icon>
                      )}

                      {hiddenViews.includes(typeView) ? null : (
                        <Icon
                          title="Ver"
                          className="cursor-pointer"
                          onClick={() => handleOpenModal(row, "view")}
                          style={{ color: "#186A3B", fontSize: "27px" }}
                        >
                          visibility__icon
                        </Icon>
                      )}

                      {hiddenDeletes.includes(typeView) ||
                      isCancel.includes(row.estado) ||
                      permits?.deleted === false ? null : (
                        <Icon
                          title="Eliminar"
                          onClick={() => deleteData(row)}
                          className="cursor-pointer"
                          style={{ color: "#186A3B", fontSize: "27px" }}
                        >
                          delete_icon
                        </Icon>
                      )}

                      {hiddenPrint.includes(typeView) ||
                      isCancel.includes(row.estado) ||
                      (!permits?.print &&
                        isPermitsPrint.includes(typeView)) ? null : (
                        <Print
                          ComponentToPrint={ComponentToPrint}
                          print={print}
                          row={row}
                        />
                      )}

                      {viewDeleteX.includes(typeView) &&
                      !isCancel.includes(row.estado) &&
                      permits?.cancel ? (
                        <Icon
                          title="Anular"
                          className="cursor-pointer"
                          style={{ color: "red" }}
                          onClick={() => deleteData(row)}
                        >
                          highlight_off_icon
                        </Icon>
                      ) : null}

                      {typeView === "cuentas-por-cobrar" &&
                      row.conFactura === "False" &&
                      row.origen === "Venta" ? (
                        <img
                          src={Invoice}
                          alt="factura"
                          style={{ width: "22px" }}
                          className="cursor-pointer"
                          onClick={() => deleteData(row)}
                          title={titleIcon}
                        />
                      ) : null}

                      {typeView === "roles" ? (
                        <Icon
                          onClick={() => handleOpenModal(row, "roles")}
                          className="cursor-pointer"
                          title="Click para actualizar permisos y accesos"
                        >
                          verified_user
                        </Icon>
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}

            {rowsPerPage > 5 && (
              <TableRow style={{ height: rowsPerPage }}></TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {typeView === "ventas" ? (
        <TablePagination
          rowsPerPageOptions={[500, 1500, 2500]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <Grid className="flex">
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginRight: "195px",
                }}
              >
                {`Importe Total Venta: ${formatNumber(
                  data
                    .map((row) => row.costoTotalDescuento)
                    .reduce((a, b) => a + b, 0)
                )} Bs`}
                &nbsp;&nbsp; |=>| &nbsp;&nbsp;
                {`Total PVT: ${formatNumber(
                  data.map((row) => row.pvtDescuento).reduce((a, b) => a + b, 0)
                )}`}
              </div>
              <span style={{ marginTop: "2px" }}>Filas por página</span>
            </Grid>
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      ) : (
        <React.Fragment>
          {isPaginationService ? (
            <TablePagination
              rowsPerPageOptions={
                typeView === "inventarios" ? [] : [50, 100, 250]
              }
              component="div"
              count={
                data.length < rowsPerPage || data[0] === -1
                  ? (page + 1) * rowsPerPage
                  : data.length + rowsPerPage * (page + 1)
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          ) : (
            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </React.Fragment>
      )}
    </Paper>
  </div>
);

export default TablePage;
