export const STATES = "STATES";
export const DATA_ENCUESTAS = "DATA_ENCUESTAS";
export const PAGE = "PAGE"; //Refactorizar
export const ROWS_PER_PAGE = "ROWS_PER_PAGE"; //Refactorizar
export const OPEN_MODAL = "OPEN_MODAL"; //Refactorizar
export const OPEN_MODALVIEW = "OPEN_MODALVIEW"; //Refactorizar
export const DATA_ENCUESTA_EDIT = "DATA_ENCUESTA_EDIT"; //Refactorizar
export const UPDATE_COMPONENT = "UPDATE_COMPONENT"; //Refactorizar
export const OPEN_DIALOG_ALERT = "OPEN_DIALOG_ALERT"; //Refactorizar
export const SUCCESS = "SUCCESS"; //Refactorizar
export const ERROR = "ERROR"; //Refactorizar
export const LISTA_ERRORS = "LISTA_ERRORS"; //Refactorizar
