import React, { useRef } from 'react'
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";
import { FormHelperText } from '@material-ui/core';

const CheckboxGroupCheck = ({
    handleChange,
    question,
    addToRefs,
    addToRefsQuestions,
}) => {

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "left",
                }}
            >
                <FormControl sx={{ m: 3 }} variant="standard">
                    <FormGroup ref={addToRefsQuestions} id={question?.code}>
                        {
                            question?.options.map(option => (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={option.key}
                                                defaultChecked={question?.response?.opciones?.find(opc => opc.keyResponse == option.key) ?
                                                    true : false}
                                                onChange={(e) => { handleChange(e, question, option, null, null) }}
                                                inputProps={{ 'aria-current': option.otro.toString() }}
                                            />
                                        }
                                        label={<span style={{ fontSize: 15 }}>{option.value}</span>}
                                    />

                                    {option.otro === true ?
                                        <TextField
                                            ref={addToRefs}
                                            id={option.key}
                                            variant="outlined"
                                            fullWidth
                                            label=""
                                            defaultValue={question?.response?.opciones?.find(opc => opc.keyResponse == option.key) ? question?.response?.respuesta : null}
                                            hidden={question?.response?.opciones?.find(opc => opc.keyResponse == option.key) ?
                                                false : true}
                                            className='mb-3'
                                            onChange={(e) => { handleChange(e, question, option, null, null) }}
                                            InputLabelProps={{ style: { fontSize: 15 } }}
                                            inputProps={{ style: { fontSize: 15 } }}
                                        />
                                        : null
                                    }
                                </>
                            )
                            )
                        }
                    </FormGroup>
                </FormControl>
            </Box>
        </div>
    )
}

export default CheckboxGroupCheck