import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";

export const useAxios = (service) => {
  const dispatchRedux = useDispatch();

  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    service()
      .then((resolve) => setState({ data: resolve, loading: false }))
      .catch((err) => {
        dispatchRedux(
          showMessage({
            message: err.message || err, //text or html
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: err.status === 401 ? "warning" : "error", //success error info warning null
          })
        );
      });

    return () => {
      setState({
        data: [],
        loading: true,
      });
    };
  }, []);

  return state; // { data:[], loading: true };
};
