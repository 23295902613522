import React from 'react'
import TerminosCondicionesProvider from "./context/TerminosCondicionesContext";
import TerminosCondionesPage from "./components/TerminosCondicionesPage";

const TerminosCondiciones = () => {
  return (
    <TerminosCondicionesProvider>
        <TerminosCondionesPage />
    </TerminosCondicionesProvider>
  )
}

export default TerminosCondiciones