import React from 'react';
import TableCell from '@material-ui/core/TableCell';

const Rows = ({ labelId, row }) => (
	<React.Fragment>
		<TableCell component="th" id={labelId} scope="row" padding="none" align="center">
			{row.descripcion}
		</TableCell>
	</React.Fragment>
)

export default Rows;