import React from "react";
import { showMessage } from "app/store/fuse/messageSlice";

const withFilter = (ComponentNew) => {
  const hoc = class extends React.Component {
    constructor(props) {
      super(props);
    }

    onSubmitHOC = (obj) => {
      const { rowsPerPage } = this.props;

      const {
        model,
        setDataFilter,
        service,
        typeService,
        dispatch,
        dispatchRedux,
        TYPE,
        TYPE_PAGE,
      } = obj;

      model.page = 1;
      model.total = rowsPerPage;
      setDataFilter(model);

      dispatch({ type: TYPE, payload: [] });
      dispatch({ type: TYPE_PAGE, payload: 0 });

      service[typeService](model)
        .then((resolve) => dispatch({ type: TYPE, payload: resolve }))
        .catch((err) => {
          dispatchRedux(
            showMessage({
              message: err.message || err, //text or html
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: err.status === 401 ? "warning" : "error", //success error info warning null
            })
          );
          dispatch({
            type: TYPE,
            payload: [-1],
          });
        });
    };

    render() {
      return <ComponentNew {...this.props} onSubmitHOC={this.onSubmitHOC} />;
    }
  };

  hoc.displayName = `withFilter(${
    ComponentNew.displayName || ComponentNew.name
  })`;

  return hoc;
};

export default withFilter;
