import React from "react";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import Spinner from "../../layaut/Spinner";
import ModalTab from "../../layaut/ModalView";
import { ButtonStyle } from "../style";
import TablePage from "./TablePage";

const ModalPermissionsPage = ({
  addPermit,
  classes,
  editProgram,
  handleCloseModal,
  isOpenModal,
  programsByRol,
  removeProgram,
}) => (
  <ModalTab
    handleCloseModal={handleCloseModal}
    isOpen={isOpenModal}
    updateDataEdit={editProgram}
  >
    <span className="tab p-14 cursor-pointer font-black">Permisos</span>

    <Paper elevation={3} className={classes.paper}>
      {Object.keys(programsByRol).length === 0 ? (
        <Spinner />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              Rol: {programsByRol.rol}
            </Typography>

            <ButtonStyle>
              <Button
                variant="contained"
                color="primary"
                endIcon={<Icon>add</Icon>}
                onClick={addPermit}
              >
                Agregar
              </Button>
            </ButtonStyle>
          </Grid>

          <Grid item xs={9}>
            <TablePage
              body={programsByRol.permisos}
              editProgram={editProgram}
              heads={[
                { name: "Programa", align: "left" },
                { name: "Acciones", align: "right" },
              ]}
              removeProgram={removeProgram}
              toAccesBody="programa"
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  </ModalTab>
);

export default ModalPermissionsPage;
