export const DATA_ROLES = "DATA_ROLES";
export const PROGRAMS_BY_ROL = "PROGRAMS_BY_ROL";
export const UPDATE_ROLES = "UPDATE_ROLES";
export const PAGE = "PAGE";
export const ROWS_PER_PAGE = "ROWS_PER_PAGE";
export const OPEN_MODAL_PERMISSIONS = "OPEN_MODAL_PERMISSIONS";
export const PROGRAMS = "PROGRAMS";
export const ID_ROL = "ID_ROL";
export const DATA_ROLES_DETAIL = "DATA_ROLES_DETAIL";
export const PERMITS = "PERMITS";
