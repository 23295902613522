import styled from 'styled-components';

export const LoginStyle = styled.section`

	.border {
		border: 20px solid #39190E;
	}

	.form___button {
		background-color: #688B0D;
		width: 150px;
	}

	.form__forgotMyPassword {
		color: white;
	}

	@media (min-width: 900px) {
		.img-logo {
			margin-left: 5%;
		}
	}

	@media (max-width: 599px) {
		.form__inputEmail {
			width: 222px;
		}
	}

	@media (max-width: 425px) {
		&.p-72 {
			padding: 3rem !important;
		}
	}
`;