import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useReactToPrint } from "react-to-print";
import { BlockUiContext } from "../../BlockUiContext";

import Icon from "@material-ui/core/Icon";

const Print = ({ ComponentToPrint, print, row, titleModal }) => {
  const { setIsBlock } = useContext(BlockUiContext);

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const handleAfterPrint = useCallback(() => {
    setLoading(false);
  }, []);

  const handleBeforePrint = useCallback(() => {
    setIsBlock(false);
  }, []);

  const handleOnBeforeGetContent = useCallback(
    (dataRow) => {
      print(dataRow, setData, setIsBlock);
      setIsBlock(true);

      return new Promise((resolve) => {
        onBeforeGetContentResolve.current = resolve;
      });
    },
    [setLoading, setData]
  );

  useEffect(() => {
    if (data.payload) setLoading(true);

    if (
      Object.keys(data).length > 0 &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, data]);

  useEffect(() => {
    return () => {
      setLoading(false);
      setData({});
    };
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: ".",
    onBeforeGetContent: () => handleOnBeforeGetContent(row),
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <div className="flex items-center">
      {titleModal === "Imprimir Comprobante" ? (
        <Icon
          title="Imprimir"
          className="cursor-pointer"
          style={{ fontSize: "40px", color: "#FFFF" }}
          onClick={handlePrint}
        >
          print_icon
        </Icon>
      ) : (
        <Icon title="Imprimir" className="cursor-pointer" onClick={handlePrint}>
          print_icon
        </Icon>
      )}

      {titleModal === "Imprimir Comprobante" ? (
        <span
          className="ml-4 cursor-pointer"
          style={{ fontSize: "20px", color: "#FFFF" }}
          onClick={handlePrint}
        >
          Imprimir
        </span>
      ) : null}

      {loading && <ComponentToPrint ref={componentRef} data={data.payload} />}
    </div>
  );
};

export default Print;
