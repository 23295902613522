import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";

import TwoButtons from "./TwoButtons";
import { ModalStyle } from "./style";
import CloseModal from "./CloseModal";

//modal dialog
import { Dialog, Typography, IconButton } from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalView = ({
  children,
  errors,
  handleCloseModal,
  isOpen,
  isTwoButtons = true,
  title,
  updateModalHeight = [],
  updateDataEdit = {},
  handleBackdropClick,
}) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const nameUrl = pathname.replace(/[/-]/gi, "");

  const modalRef = useRef(null);

  const [heightModal, setHeightModal] = useState(0);
  const [isScroll, setIsScroll] = useState(null);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (!modalRef.current) return;

      const { current } = modalRef;

      isOpen && setHeightModal(current.scrollHeight);
    }, 0);

    return () => window.clearTimeout(timeout);
  }, [modalRef, isOpen, errors, updateModalHeight, updateDataEdit]);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      isOpen && setIsScroll(heightModal > window.innerHeight);
    }, 0);

    if (!isOpen) {
      setHeightModal(0);
      setIsScroll(null);
    }

    return () => window.clearTimeout(timeout);
  }, [heightModal, isOpen, errors, updateModalHeight, updateDataEdit]);

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <ModalStyle
          className="p-20"
          nameUrl={nameUrl}
          isModalView={true}
          isScroll={isScroll}
          title={title}
          ref={modalRef}
        >
          {children}
        </ModalStyle>
      </DialogContent>
      <DialogActions>
        {isTwoButtons ? (
          <TwoButtons
            text2="Salir"
            classes1="mr-20"
            icon1="save_icon"
            handleClick={handleCloseModal}
          />
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default ModalView;
