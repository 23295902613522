import { createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import jwtService from "app/services/jwtService";
import { setUserData } from "./userSlice";
import { encuestas } from "app/services/encuestas/encuestas";

export const submitLogin = (data, setIsBlock) =>
  async (dispatch) => {
    return jwtService
      .signInWithUsernameAndPassword(data.username, data.password)
      .then((data) => {
        setIsBlock(false);
        dispatch(setUserData(data))
        return dispatch(loginSuccess(data));
      })
      .catch((error) => {
        setIsBlock(false);
        return dispatch(
          showMessage({
            message: error.response.data.Data.Message, //text or html
            autoHideDuration: 500000, //ms
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "warning", //success error info warning null
          })
        );
      });
  };

export const submitAuthExterno = (data) =>
  async (dispatch) => {
    return jwtService
      .signInWithAuthenticationExterno(data)
      .then((response) => {
        dispatch(setUserData(response))
        return dispatch(loginSuccess(response));
      })
      .catch((errors) => {
        return dispatch(loginError(errors));
      });
  };

export const submitRegisterExterno = (data) =>
  async (dispatch) => {
    return jwtService
      .signInWithRegisterExterno(data)
      .then((response) => {
        dispatch(setUserData(response))
        return dispatch(loginSuccess(response));
      })
      .catch((errors) => {
        return dispatch(loginError(errors));
      });
  };

const initialState = {
  success: false,
  errors: [],
  codeEncuesta: null,
};

const loginSlice = createSlice({
  name: "auth/login",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    loginError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
