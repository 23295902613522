import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Button from "@material-ui/core/Button";
import RadioGroup from "./RadioGroupCheck";
import CheckboxGroupCheck from "./CheckboxGroupCheck";
import TableCategorias from "./TableCategorias";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import FloatingButton from "./BotonFloating";
import LoadingSpinner from './LoadingSpinner';
import ReactHtmlParser from 'react-html-parser';
import "../style.css";
import { color } from "framer-motion";

const FormEncuestaPage = ({
	dataEncuesta,
	onSubmit,
	onSubmitFloating,
	dispatchEncuestas,
	handleChange,
	register,
	handleSubmit,
	errors,
	error,
	handleChangeCheck,
	stateCheckedOtro,
	checked,
	keyChecked,
	listaErros,
	addToRefs,
	addToRefsRb,
	addToRefsQuestions,
	listaErrores,
	checkedRB,
	today,
}) => {

	const { data } = dataEncuesta;
	return (

		<div>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				marginTop={"1.5rem"}
				textAlign="center"
			>
				<Paper
					variant="outlined"
					elevation={0}
					sx={{ width: "100%", marginTop: "2rem", padding: "3rem" }}
				>
					<form className="formEncuesta">
						{data ?
							<>
								<h2 style={{ fontSize: 18, fontWeight: 'bold' }}>{ReactHtmlParser(data?.title)}</h2>

								{data?.questions[0].categoria === "Autorizacion" && data?.questions[0].response === undefined ?
									<div className="container-question-autorizacion">
										<h2 className="flex flex-col mt-5" style={{ textAlign: 'justify' }}>
											<span style={{ fontSize: '17px' }}>{ReactHtmlParser(data?.questions[0].question)}</span>
										</h2>
										{
											data?.questions[0].typeQuestion?.code === "SEL_SIMP_CHECK" ?
												<div className="">
													<RadioGroup
														register={register}
														question={data?.questions[0]}
														handleChange={handleChange}
														addToRefsRb={addToRefsRb}
														addToRefsQuestions={addToRefsQuestions}
														checkedRB={checkedRB}
													/>
												</div>
												: null
										}
									</div>
									:
									<>
										{data?.questions.map((question, index) => {
											return (
												<>
													<div className="container-question" key={index}>
														<h2 className="flex flex-col mt-5">
															<span style={{ fontSize: '17px' }}>{
																question?.categoria != "Autorizacion" ?
																	ReactHtmlParser(question?.question) : null
															}
															</span>
															{listaErrores.map((item) => {
																if (item.code === question.code) {
																	return (
																		<>
																			<span style={{ fontSize: '14px', color: 'red' }}>{`* ${item.message}`}</span>
																		</>
																	)
																}

															})
															}

															{listaErros.find(err => err.codeQuestion === question.code)?.validaciones?.map((val) => {
																return (
																	<>
																		<span style={{ fontSize: '14px', color: 'red' }}>{`* ${val.message}`}</span>
																	</>
																)
															})}

														</h2>
														{
															question?.typeQuestion.code === "VAL_SIMPLE" ?
																<div className="w-96">
																	<TextField
																		ref={addToRefsQuestions}
																		id="outlined-basic"
																		name={`${question?.code}`}
																		variant="outlined"
																		sx={{ width: "100%" }}
																		defaultValue={question?.response?.respuesta ? question?.response?.respuesta : ""}
																		label=""
																		type={question?.typeDate.code === "NUMERIC" ? "number"
																			: question?.typeDate.code === "DATE" ? "date"
																				: "text"}
																		onKeyDown={(e) => {
																			if (question?.typeDate.code === "NUMERIC" && (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+")) {
																				e.preventDefault()
																			}
																		}}
																		onChange={(e) => { handleChange(e, question) }}
																		InputProps = {{
																			inputProps:{
																				min: question?.min,
																				max: question?.typeDate.code === "DATE" && question?.max === 0 ? today : 
																				question?.max > 0 ? question?.max : 9999999999,
																				step: 1,
																				maxLength: 100,
																				style: { fontSize: 15 }
																		}
																		}}
																		
																	/>
																</div>
																: null
														}
														{
															question?.typeQuestion.code === "SEL_SIMP_CHECK" && question?.categoria != "Autorizacion" ?
																<div className="">
																	<RadioGroup
																		register={register}
																		question={question}
																		handleChange={handleChange}
																		addToRefsQuestions={addToRefsQuestions}
																	/>
																</div>
																: null
														}

														{
															question?.typeQuestion.code === "SEL_MULT_CHECK" ?
																<div className="">
																	<CheckboxGroupCheck
																		register={register}
																		handleChange={handleChange}
																		question={question}
																		addToRefs={addToRefs}
																		addToRefsQuestions={addToRefsQuestions}
																	/>
																</div>
																: null
														}

														{
															question?.typeQuestion.code === "CATEGORIAS" || question?.typeQuestion.code === "CATEGORIA_UNICA" ?
																<div className="">
																	<TableCategorias
																		description={question?.description}
																		typeDate={question?.typeDate.code}
																		question={question}
																		register={register}
																		errors={errors}
																		handleChange={handleChange}
																		addToRefsQuestions={addToRefsQuestions}
																	/>
																</div>
																: null
														}
													</div>
												</>
											)
										})}
										<br />
										<br />
										<div className="container-boton">
											<Button
												type="submit"
												variant="contained"
												aria-label="LOG IN"
												className="btn-enviar"
												value="legacy"
												size="small"
												onClick={onSubmit}
											>
												Enviar
											</Button>
										</div>
										<div>
											<div className="app">
												<FloatingButton onClick={onSubmitFloating} />
											</div>
										</div>
									</>
								}
							</>
							: <LoadingSpinner />
						}
					</form>
				</Paper>
			</Box>
		</div>
	);
};

export default FormEncuestaPage;
