import {
  PAGE,
  ROWS_PER_PAGE,
  DATA_ROLES,
  UPDATE_ROLES,
  OPEN_MODAL_PERMISSIONS,
  PROGRAMS_BY_ROL,
  PROGRAMS,
  ID_ROL,
  DATA_ROLES_DETAIL,
  PERMITS,
} from "./types";

export const initialState = {
  programsByRol: [],
  dataRoles: [],
  updateRoles: false,
  page: 0,
  rowsPerPage: 50,
  isOpenModalPermissions: false,
  programs: [],
  idRol: null,
  dataRolesDetail: {},
  permits: {},
};

export function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case PROGRAMS_BY_ROL:
      return {
        ...state,
        programsByRol: payload,
      };

    case DATA_ROLES:
      return {
        ...state,
        dataRoles: payload,
      };

    case UPDATE_ROLES:
      return {
        ...state,
        updateRoles: payload,
      };

    case PAGE:
      return {
        ...state,
        page: payload,
      };

    case ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: payload,
      };

    case OPEN_MODAL_PERMISSIONS:
      return {
        ...state,
        isOpenModalPermissions: payload,
      };

    case PROGRAMS:
      return {
        ...state,
        programs: payload,
      };

    case ID_ROL:
      return {
        ...state,
        idRol: payload,
      };

    case DATA_ROLES_DETAIL:
      return {
        ...state,
        dataRolesDetail: payload,
      };

    case PERMITS:
      return {
        ...state,
        permits: payload,
      };

    default:
      return state;
  }
}
