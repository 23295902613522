import React from "react";
import { showMessage } from "app/store/fuse/messageSlice";

const withModal = (ComponentNew) => {
  const hoc = class extends React.Component {
    constructor(props) {
      super(props);
    }

    handleOpenModal = ({ isEdit, dataEdit, dispatch, TYPES }) => {
      const [OPEN, DATA_EDIT] = TYPES;

      isEdit
        ? dispatch({ type: DATA_EDIT, payload: dataEdit })
        : dispatch({ type: DATA_EDIT, payload: {} });

      dispatch({ type: OPEN, payload: true });
    };

    handleCloseModal = ({ dispatch, TYPES, clearErrors }) => {
      const [OPEN, ...rest] = TYPES;

      dispatch({ type: OPEN, payload: false });
      rest.forEach((TYPE) => dispatch({ type: TYPE, payload: [] }));

      clearErrors();
    };

    handleOpenModalView = ({ id, dispatch, TYPES, service, dispatchRedux }) => {
      const [OPEN, DATA] = TYPES;

      dispatch({ type: OPEN, payload: true });

      service(id)
        .then((resolve) => dispatch({ type: DATA, payload: resolve }))
        .catch((err) => {
          dispatchRedux(
            showMessage({
              message: err.message || err, //text or html
              anchorOrigin: {
                vertical: "top", //top bottom
                horizontal: "center", //left center right
              },
              variant: err.status === 401 ? "warning" : "error", //success error info warning null
            })
          );
        });
    };

    render() {
      return (
        <ComponentNew
          {...this.props}
          handleOpenModalHOC={this.handleOpenModal}
          handleCloseModalHOC={this.handleCloseModal}
          handleOpenModalViewHOC={this.handleOpenModalView}
        />
      );
    }
  };

  hoc.displayName = `withModal(${
    ComponentNew.displayName || ComponentNew.name
  })`;

  return hoc;
};

export default withModal;
