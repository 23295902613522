import React, { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { showMessage } from "app/store/fuse/messageSlice";
import { BlockUiContext } from "../../../BlockUiContext";
import jwt_decode from "jwt-decode"
import RegisterUserPage from "../components/RegisterUserPage";
import {
  schemaFormModalUser,
  defaultValuesFormModalUser,
} from "../utils/helper";
import { LoginContext } from "../context/LoginContext";
import {
  DATA_USER,
  UPDATE_COMPONENT,
  OPEN_MODAL_USER,
} from "../types";
import { user } from "../../../services/users/user";
import jwtService from "../../../services/jwtService"
import withModal from "../../HOC/withModal";
import { callAPIregister, callAPI } from "../../utils/callAPIS";
import { submitRegisterExterno } from "../../../auth/store/loginSlice";

const RegisterUser = ({ handleSelectHOC, handleCloseModalHOC, positions }) => {
  const dispatch = useDispatch();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [countIntentosValue, setCountIntentosValue] = useState(0);
  const [showMessageReCaptcha, setShowMessageReCaptcha] = useState(null);
  const { control, handleSubmit, setValue, formState, getValues, clearErrors } =
    useForm(
      {
        defaultValues: defaultValuesFormModalUser,
        resolver: yupResolver(schemaFormModalUser),
      }
    );

  const { errors } = formState;

  const {
    stateLogin: {
      isOpenModalUser,
    },
    dispatchLogin,
  } = useContext(LoginContext);

const [email, setEmail] = useState("");
  const { setIsBlock } = useContext(BlockUiContext);
  const data = {
    id: "",
    nombres: "",
  };

  useEffect(() => {
    if(email && errors.username){
      clearErrors("username");
    }
  }, [email]);

  const handleCloseModal = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    handleCloseModalHOC({
      dispatch: dispatchLogin,
      TYPES: [OPEN_MODAL_USER],
      clearErrors,
    });
    setCountIntentosValue(0);
    setShowMessageReCaptcha(null);

    // Limpia los campos del formulario del modal.
    return ["names", "lastNames", "password", "email", "username"].forEach(
      (value) => setValue(value, "", true)
    );
  };

  // Cuando registra o edita un usuario
  function onSubmit(model) {
    setCountIntentosValue(countIntentosValue + 1)
    if(model.username === undefined && model.email === undefined) return;
    if (countIntentosValue > 2 && recaptchaValue === null){
      setShowMessageReCaptcha("Por Favor debe completar el ReCAPTCHA.");
      return;
    } 

    const dataNewUser = {
      username: model.username.trim(),
      email: model.email,
      nombres: model.names,
      apellidos: model.lastNames,
      password: model.password
    }

    callAPIregister(
      { service: jwtService, type: "createUser" },
      dataNewUser,
      null,
      dispatchLogin,
      DATA_USER,
      null,
      dataNewUser,
      dispatch,
      handleCloseModal,
      setIsBlock
    );

    setIsBlock(true);
  }

  const responseGoogle = (response) => {
    var decoded = jwt_decode(response.credential)
    const data = {
      email: decoded.email,
      username: "",
      externalToken: decoded.jti,
      appExternal: "Google",
      nombres: decoded.given_name,
      apellidos: decoded.family_name,
      typeToken: "jwt"
    }
    dispatch(submitRegisterExterno(data));
  }

  const onChangeRecaptcha = (value) => {
    setRecaptchaValue(value);
    if(value)setShowMessageReCaptcha(null);
  }

  const responseFacebook = (response) => { 
    const data = {
      email: response.data.email,
      username: "",
      externalToken: response.data.accessToken,
      appExternal: "Facebook",
      nombres: response.data.first_name,
      apellidos: response.data.last_name,
      typeToken: "jwt"
    }
    
    dispatch(submitRegisterExterno(data));
  }
  
  return (
    <RegisterUserPage
      control={control}
      errors={errors}
      isOpenModal={isOpenModalUser}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      positions={positions}
      setValue={setValue}
      onChangeRecaptcha={onChangeRecaptcha} 
      responseGoogle={responseGoogle}
      recaptchaValue={recaptchaValue}
      countIntentosValue={countIntentosValue}
      showMessageReCaptcha={showMessageReCaptcha}
      responseFacebook={responseFacebook}
      setEmail={setEmail}
    />
  );
};

export default withModal(RegisterUser);
