import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { submitRegister } from 'app/auth/store/registerSlice';
import * as yup from 'yup';

import JWTRegisterTabPage from '../components/JWTRegisterTabPage';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	displayName: yup.string().required('You must enter display name'),
	email: yup.string().email('You must enter a valid email').required('You must enter a email'),
	password: yup
	.string()
	.required('Please enter your password.')
	.min(3, 'Password is too short - should be 3 chars minimum.'),
	passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const defaultValues = {
	displayName: '',
	email: '',
	password: '',
	passwordConfirm: '',
};

const JWTRegisterTab = () => {
	
	const dispatch = useDispatch();
	const authRegister = useSelector(({ auth }) => auth.register);

	const { control, formState, handleSubmit, reset, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { isValid, dirtyFields, errors } = formState;

	useEffect(() => {

		authRegister.errors.forEach((error) => {
			setError(error.type, {
				type: 'manual',
				message: error.message,
			});
		});

	}, [authRegister.errors, setError]);

	function onSubmit(model) {
	}

	return (
		<JWTRegisterTabPage
			control={control}
			dirtyFields={dirtyFields}
			errors={errors}
			handleSubmit={handleSubmit}
			isValid={isValid}
			onSubmit={onSubmit}
		/>
	)
}

export default JWTRegisterTab;