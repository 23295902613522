import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";


import { LoginStyle } from "./style";
import JWTLoginTab from "./container/JWTLoginTab";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import UserProvider from './context/LoginContext';
import RegisterUser from "./container/RegisterUser";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E5E5E5",
    color: theme.palette.primary.contrastText,
  },
  leftSection: {},
  rightSection: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark
      } 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
    color: theme.palette.primary.contrastText,
  },
  containerForm: {
    backgroundColor: "#BFAA6B",
  },
}));

const Login = ({ history }) => {
  const classes = useStyles();

  const isMin600 = useMediaQuery("(min-width:600px)");

  return (
    <UserProvider>
      <JWTLoginTab history={history} isMin600={isMin600} />
      <RegisterUser />
    </UserProvider>
  )
};

export default Login;
