import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

import TwoButtons from "./TwoButtons";
import { ModalStyle } from "./style";
import CloseModal from "./CloseModal";

import { Dialog, Typography, IconButton } from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalForm = ({
  errors,
  handleCloseModal,
  isOpen,
  onSubmit,
  handleSubmit,
  children,
  isViewButtons,
  nameComponent = "",

  title,
  text1 = "Guardar",
  text2 = "Cancelar",
  text3 = "Guardar",
  updateModalHeight = [],
  updateDataEdit = {},
}) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const nameUrl = pathname.replace(/[/-]/gi, "");

  const modalRef = useRef(null);

  const [heightModal, setHeightModal] = useState(0);
  const [isScroll, setIsScroll] = useState(null);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (!modalRef.current) return;

      const { current } = modalRef;
      isOpen && setHeightModal(current.scrollHeight);
    }, 0);

    return () => window.clearTimeout(timeout);
  }, [modalRef, errors, updateModalHeight, updateDataEdit]);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      isOpen && setIsScroll(heightModal > window.innerHeight);
    }, 0);

    if (!isOpen) {
      setHeightModal(0);
      setIsScroll(null);
    }

    return () => window.clearTimeout(timeout);
  }, [heightModal, isOpen, errors, updateModalHeight, updateDataEdit]);

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      maxWidth="lg"
    >
      <form
        noValidate
        autoComplete="off"
        className="flex flex-col overflow-x-scroll"
        onSubmit={handleSubmit(onSubmit)}
        style={{backgroundColor:"#F3DAE3"}}
      >
        <DialogTitle id="customized-dialog-title" style={{ backgroundColor: "#8A133D" }} onClose={handleCloseModal}>
        <span style={{color: '#FFFFFF'}}>Registrarme</span>
        </DialogTitle>
        <DialogContent dividers style={{backgroundColor:"#F3DAE3"}}>
          <ModalStyle
            className="pl-10"
            isScroll={isScroll}
            heightModal={10}
            heightForm={10}
            nameUrl={nameUrl}
            ref={modalRef}
            title={title}
          >
            {children}
          </ModalStyle>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#8A133D" }}>
          {isViewButtons ? (
            <TwoButtons
              text1={text1}
              text2={text2}
              text3={text3}
              classes1="mr-20"
              icon1="save_icon"
              handleClick={handleCloseModal}
            />
          ) : null}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalForm;
