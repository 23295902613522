import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormEncuestaPage from "../components/FormEncuestaPage";
import FormInterrogation from "../components/FormInterrogation";
import FormAgradecimiento from "../components/FormAgradecimiento";
import FormSuccess from "../components/FormSuccess";
import FormError from "../components/FormError";
import { EncuestasContext } from "../context/EncuestasContext";
import { useValueDefault } from "../../customHooks/useValueDefault";
import { registerSchema } from "../AlmacenesSchema";
import { useAxios } from "../../customHooks/useAxios";
import withFilter from "../../HOC/withFilter";
import withSelect from "../../HOC/withSelect";
import { encuesta } from "../../../services/encuestas/encuestas";
import { callAPIregister } from "app/main/utils/callAPIS";
import { showMessage } from "app/store/fuse/messageSlice";
import { prepareAutoBatched } from "@reduxjs/toolkit";
import {
  ERROR,
  SUCCESS,
  LISTA_ERRORS,
  UPDATE_COMPONENT,
} from "../types";
import { format } from 'date-fns';
import Swal from "sweetalert2";

const FormEncuesta = ({
  onSubmitHOC,
  handleSelectcliente,
}) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const respuesta = {
    id: 0,
    respuesta: "",
    codigoPregunta: "",
    opciones: [],
    categorias: []
  }
  const [formValues, setFormValues] = useState([]);

  const {
    stateEncuestas: { states, dataEncuestas, isSuccess, isError, listaErros, updateComponent, },
    dispatchEncuestas,
    /*    stateFormInt,
       stateFormAgr, */
    /*     onSubmitBtnA,
        onSubmitBtnB, */
    stateError,
    stateSuccess,
    setStateError,
    setStateSuccess,
  } = useContext(EncuestasContext);

  const [actionModal, setActionModal] = useState(false);

  const refs = useRef([]);
  refs.current = [];

  const refsRb = useRef([]);
  refsRb.current = [];

  const refsQuestions = useRef([]);
  refsQuestions.current = [];

  const [messageSuccess, setMessageSuccess] = useState("");

  const addToRefs = el => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const addToRefsRb = el => {
    if (el && !refsRb.current.includes(el)) {
      refsRb.current.push(el);
    }
  };

  const addToRefsQuestions = el => {
    if (el && !refsQuestions.current.includes(el)) {
      refsQuestions.current.push(el);
    }
  };

  const dataRespuestas = {
    codigo: 0,
    respuestaPreguntas: [],
  }

  const arrayRespuestaPreguntas = [];
  const opciones = {
    keyRespuesta: "",
    otro: ""
  }
  const categorias = {
    id: "",
    secciones: []
  }
  const secciones = {
    idSeccion: 0,
    idPregunta: 0,
    respuesta: ""
  }

  const initialState = {
    idCategory: 0,
    idSeccion: 0,
    idPregunta: 0,
    respuesta: "",
  }

  const [objectRespuestas, setObjectRespuestas] = useState(dataEncuestas.data);
  const [arraySecciones, setArraySecciones] = useState([initialState]);
  const [arrayValues, setArrayValues] = useState([]);
  const [checkedRB, setCheckedRB] = useState(false);
  const stateValues = {
    id: "",
    value: ""
  }
  const dateToday = new Date();
  var fechaActual = dateToday.getFullYear() + '-' + ( dateToday.getMonth() + 1 ) + '-' + dateToday.getDate();

  const handleChange = (e, question = null, opcion = null, seccion = null, categoria = null) => {

    //validacion valor maximo permitido type numeric 
    if(question?.typeDate?.code === 'NUMERIC' || seccion?.question?.typeDate?.code === 'NUMERIC'){
      let value = Number(e.target.value);
      let maximo = Number(e.target.max);
      if( value > maximo && (categoria === null || categoria.secciones.length > 1)){
        const valorAnterior = e.target.value.substr(0, e.target.value.length - 1);
        e.target.value = valorAnterior;
        dispatch(
          showMessage({
            message: `El número máximo permitido es ${maximo}`, //text or html
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "center", //top bottom
              horizontal: "center", //left center right
            },
            variant: "warning", //success error info warning null
          })
        );   
        return;
      }   
    }

    let result = dataEncuestas.data;

    //pregunta simple
    result = result.questions?.map((pregunta) => {
      if (pregunta.code === question?.code
        && e.target.value !== undefined
        && question.typeQuestion.code === "VAL_SIMPLE") {

        //obtenemos el valor de los textField
        stateValues.id = question?.code;
        stateValues.value = e.target.value;

        setArrayValues([...arrayValues, stateValues]);

        if (question.typeDate.code === "DATE") {
          pregunta.respuesta = format(new Date(e.target.value + 'T00:00:00'), 'dd/MM/yyyy');
        } else {
          pregunta.respuesta = e.target.value;
        }
        pregunta.respuestaState = true;
      }

      //verificacion de radio button seleccionado
      if (pregunta.code === question?.code
        && e.target.value !== undefined
        && question.typeQuestion.code === "SEL_SIMP_CHECK") {
        if (question.categoria === "Autorizacion") {
          saveAutorization(e.target, opcion, question);
        }

        const opcionesRespuesta = {
          keyRespuesta: e.target.value,
          otro: ""
        }
        pregunta.opcionesRespuesta = [opcionesRespuesta];
        pregunta.opcionesState = true;
      }

      //check multiple seleccioando
      if (pregunta.code === question?.code
        && question?.typeQuestion.code === "SEL_MULT_CHECK") {
        if (opcion.otro === true) {
          if (e.target.checked) {

            refs.current.forEach((ref) => {
              ref.childNodes.forEach((chil) => {
                chil.childNodes.forEach((x) => {
                  if (x.id === opcion.key) {
                    ref.hidden = false;
                    return;
                  }
                })
              })
            });
          } else {
            if (!e.target.checked && e.target.value === "on") {
              refs.current.forEach((ref) => {
                ref.childNodes.forEach((chil) => {
                  chil.childNodes.forEach((x) => {
                    if (x.id === opcion.key) {
                      ref.hidden = true;
                      return;
                    }
                  })
                })
              });
            } else {
              pregunta.respuesta = e.target.value;
              if (e.target.value === "") {
                pregunta.respuestaState = false;
              } else {
                pregunta.respuestaState = true;
              }
            }

          }
        }

        let optionResponseValues = [];
        let opcionesCheckRespuesta = {}
        pregunta?.response?.opciones?.map((option) => {

          if (option.keyResponse !== opcion.key && !e.target.checked) {
            optionResponseValues = [...optionResponseValues, option];
          }
        })

        if (!e.target.checked && e.target.value === "on") {

          pregunta.opcionesCheckRespuesta = optionResponseValues;
          if (pregunta.opcionesCheckRespuesta.length > 0) {
            pregunta.opcionesChekState = true;
          } else {
            pregunta.opcionesChekState = false;
          }
        }

        ///cuando se selecciona un check
        if (e.target.checked) {
          opcionesCheckRespuesta.keyRespuesta = opcion.key;
          if (pregunta.opcionesCheckRespuesta === undefined && pregunta?.response === undefined) {
            pregunta.opcionesCheckRespuesta = [opcionesCheckRespuesta];
          } else {
            if (pregunta?.response?.opciones.length > 0) {
              pregunta.opcionesCheckRespuesta = [...pregunta?.response.opciones, opcionesCheckRespuesta];
            } else {
              pregunta.opcionesCheckRespuesta = [...pregunta.opcionesCheckRespuesta, opcionesCheckRespuesta];
            }

          }
          pregunta.opcionesChekState = true;
        }
      }

      //verificacion categorias  

      var itemCategoriaRespuesta = {
        id: 0,
        secciones: [],
      }

      const identifiqueCat = pregunta?.configCategoria?.categorias?.find(cat => cat?.id == categoria?.id)?.id;
      //respuesta otros

      if (identifiqueCat === categoria?.id && categoria?.otros === true
        && e.target.value !== undefined
        && seccion === null) {
        pregunta.respuesta = e.target.value;
        if (e.target.value === "") {
          pregunta.respuestaState = false;
        } else {
          pregunta.respuestaState = true;
        }

      }

      var itemCatSeccion = [];
      if (identifiqueCat > 0 && categoria && seccion) {
        initialState.idCategory = categoria.id;
        initialState.idSeccion = seccion.id;
        initialState.idPregunta = seccion.question.id;
        initialState.respuesta = e.target.value;

        const idSeccionArray = arraySecciones?.find(sec => sec.idSeccion == seccion.id)?.idSeccion;

        if (idSeccionArray == seccion.id) {

          const position = arraySecciones.findIndex((index) => index.idSeccion == seccion.id && index.idCategory == categoria.id);
          if (position === -1) {
            setArraySecciones([initialState]);
            itemCatSeccion = [initialState];
          } else {
            itemCatSeccion = [...arraySecciones];
            itemCatSeccion[position] = initialState;
            setArraySecciones(itemCatSeccion);
          }
        } else {
          itemCatSeccion = [initialState];
        }

        itemCategoriaRespuesta.id = categoria.id;
        itemCategoriaRespuesta.secciones = itemCatSeccion;

        //proceder a cargar;
        pregunta.categoriasState = true;

        const idCategory = pregunta.categoriasRespuesta?.find(cat => cat.id == categoria.id)?.id;

        if (idCategory == undefined && pregunta.response && pregunta.response.categorias) {
          var catResponse = pregunta.response.categorias?.find(cat => cat.id == categoria.id);
          if (catResponse && catResponse.secciones) {
            for (let index = 0; index < catResponse.secciones.length; index++) {
              let seccionCat = catResponse.secciones[index];
              if (seccionCat.id != seccion.id) {
                let secResp = {
                  "idCategory": catResponse.id,
                  "idPregunta": seccion.question.id,
                  "idSeccion": seccionCat.id,
                  "respuesta": seccionCat.respuesta
                };
                itemCatSeccion.push(secResp);
              }

            }
            itemCategoriaRespuesta.secciones = itemCatSeccion;
            if (pregunta.categoriasRespuesta == undefined)
              pregunta.categoriasRespuesta = [];

            pregunta.categoriasRespuesta.push(itemCategoriaRespuesta);

            itemCatSeccion = null;
          }
          for (let indexC = 0; indexC < pregunta.response.categorias.length; indexC++) {
            let cateresp = pregunta.response.categorias[indexC];
            if (cateresp.id != categoria.id) {
              let catResponse = {
                id: cateresp.id,
                secciones: []
              };
              if (cateresp.secciones) {
                for (let index = 0; index < cateresp.secciones.length; index++) {
                  let seccionCat = cateresp.secciones[index];
                  let secResp = {
                    "idCategory": cateresp.id,
                    "idPregunta": 0,
                    "idSeccion": seccionCat.IdSeccion,
                    "respuesta": seccionCat.respuesta
                  };
                  catResponse.secciones.push(secResp);

                }
              }
              if (pregunta.categoriasRespuesta == undefined)
                pregunta.categoriasRespuesta = [];

              pregunta.categoriasRespuesta.push(catResponse);
            }

          }
        }
        if (idCategory == categoria.id) {
          const indexCategoria = pregunta.categoriasRespuesta.findIndex((index) => index.id == categoria.id);
          // Si la categoria no existe
          if (indexCategoria === -1)
            pregunta.categoriasRespuesta = [itemCategoriaRespuesta];
          else {
            let indexSeccion = pregunta.categoriasRespuesta[indexCategoria].secciones.findIndex(se => se.idSeccion == seccion.id);
            if (indexSeccion >= 0) {
              pregunta.categoriasRespuesta[indexCategoria].secciones[indexSeccion] = itemCatSeccion[0];
            } else {
              pregunta.categoriasRespuesta[indexCategoria].secciones.push(itemCatSeccion[0]);
            }
          }
        } else {
          if (pregunta.categoriasRespuesta == undefined) {
            pregunta.categoriasRespuesta = [];
          }
          if (itemCatSeccion) {
            pregunta.categoriasRespuesta.push(itemCategoriaRespuesta);
          }

        }

      }

      //final
      return pregunta;

    });

    setObjectRespuestas(result);

  };



  const saveAutorization = (target, opcion, question) => {
    return (
      Swal.fire({
        title: target.value === "si" ? `¿Está seguro de autorizar?` : `¿Está seguro de no autorizar?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#008000"
      }).then(response => {
        if (response.isConfirmed) {
          const opcionesRespuesta = {
            keyRespuesta: target.value,
            otro: ""
          }

          const respuesta = {
            id: 0,
            respuesta: "render",
            codPregunta: question?.code,
            opciones: [opcionesRespuesta],
            categorias: []
          };
          arrayRespuestaPreguntas.push(respuesta);

          //enviar a la api
          dataRespuestas.codigo = dataEncuestas?.data?.code;
          dataRespuestas.noAutorizado = target.value === "si" ? false : true;
          dataRespuestas.respuestaPreguntas = arrayRespuestaPreguntas;
          //regisrar encuesta

          switch (target.value) {
            case 'si':
              callAPIregister(
                { service: encuesta, type: "saveEncuesta" },
                dataRespuestas,
                null,
                dispatchEncuestas,
                UPDATE_COMPONENT,
                null,
                !updateComponent,
                dispatch,
                () => { },
                () => { }
              );
              break;
            case 'no':
              callAPIregister(
                { service: encuesta, type: "saveEncuestaCompleta" },
                dataRespuestas,
                null,
                () => { },
                null,
                null,
                null,
                dispatch,
                () => { },
                () => { }
              );

              Swal.fire({
                title: "Gracias por su tiempo.",
                text: "La encuesta ya fue completada, gracias",
                icon: "success",
                confirmButtonText: "Aceptar",
              });
              if (target.value === 'no') {
                refsRb.current.forEach((ref) => {
                  ref.childNodes.forEach((chil) => {
                    chil.childNodes.forEach((x) => {
                      ref.hidden = true;
                    })
                  })
                });
              }

              break;
          }
        }

        if (response.isDenied) {
          window.location.reload(true);
        }

      })
    );
  }

  const [listaErrores, setListaErrores] = useState([]);
  let errores = [];
  let errorState = false;

  const validations = (refsQuestions) => {
    dataEncuestas.data.questions?.map((pregunta) => {
      for (let index = 0; index < refsQuestions.current.length; index++) {

        switch (pregunta.typeQuestion.code) {
          case "VAL_SIMPLE":
            if (pregunta.code === refsQuestions.current[index].childNodes[0].childNodes[0].name
              && pregunta.required
              && refsQuestions.current[index].childNodes[0].childNodes[0].value === ""
              && pregunta.typeQuestion.code === "VAL_SIMPLE") {

              const stateErrores = {
                code: pregunta.code,
                message: "Debe responder la pregunta",
              }

              errores.push(stateErrores);
              setListaErrores(errores);
              errorState = true;
            } else {

              if (pregunta.code === refsQuestions.current[index].childNodes[0].childNodes[0].name
                && refsQuestions.current[index].childNodes[0].childNodes[0].value !== ""
                && pregunta.typeQuestion.code === "VAL_SIMPLE") {
                let errorValidation = false;
                let max = Number(refsQuestions.current[index].childNodes[0].childNodes[0].max);
                let min = Number(refsQuestions.current[index].childNodes[0].childNodes[0].min);
                let value = Number(refsQuestions.current[index].childNodes[0].childNodes[0].value);

                if (value > max || value < min) {
                  errorValidation = true;
                }

                if (errorValidation) {
                  const stateErrores = {
                    code: pregunta.code,
                    message: `Número máximo admitido: ${max}`,
                  }

                  errores.push(stateErrores);
                  setListaErrores(errores);
                  errorState = true;
                }

              }
            }
            break;
          case "SEL_SIMP_CHECK":
            if (refsQuestions.current[index].id === pregunta.code
              && pregunta.required
              && pregunta.typeQuestion.code === "SEL_SIMP_CHECK") {
              let selectChecked = false;
              for (let i = 0; i < refsQuestions.current[index].childNodes[0].childNodes.length; i++) {
                if (refsQuestions.current[index].childNodes[0].childNodes[i].control.checked === true) {
                  selectChecked = true;
                }
              }

              if (selectChecked === false) {
                const stateErrores = {
                  code: pregunta.code,
                  message: "Debe elegir una opción",
                }
                errores.push(stateErrores);
                setListaErrores(errores);
                errorState = true;
              }

            }
            break;
          case "CATEGORIA_UNICA":
            if (pregunta.typeQuestion.code === "CATEGORIA_UNICA" 
            && pregunta.required
            && refsQuestions.current[index].id === pregunta.code) {
              let contador = 0;
              for (let i = 0; i < refsQuestions.current[index].childNodes[1].childNodes[0].childNodes.length; i++) {
                if (refsQuestions.current[index].childNodes[1].childNodes[0].childNodes[i].childNodes[0].childNodes[1].childNodes[0].value === "") {
                  contador = contador + 1;
                }
              }
              const stateErrores = {
                code: pregunta.code,
                message: "",
              }
              switch (contador) {
                case 1:
                  stateErrores.message = "Debe completar los campos"
                  errores.push(stateErrores);
                  setListaErrores(errores);
                  errorState = true;
                  break;
                case 2:
                  stateErrores.message = "Debe responder la pregunta"
                  errores.push(stateErrores);
                  setListaErrores(errores);
                  errorState = true;
                  break;
              }
            }
            break;
          case "SEL_MULT_CHECK":
            if (pregunta.typeQuestion.code === "SEL_MULT_CHECK" 
            && pregunta.required
            && refsQuestions.current[index].id === pregunta.code) {
              let selectChecked = false;
              for (let i = 0; i < refsQuestions.current[index].childNodes.length; i++) {
                if (refsQuestions.current[index].childNodes[i]?.control?.checked === true) {
                  selectChecked = true;
                }
              }

              if (selectChecked === false) {
                const stateErrores = {
                  code: pregunta.code,
                  message: "Debe seleccionar al menos una opción",
                }
                errores.push(stateErrores);
                setListaErrores(errores);
                errorState = true;
              }
            }
            break;
          case "CATEGORIAS":
            if (pregunta.typeQuestion.code === "CATEGORIAS" 
            && refsQuestions.current[index].id === pregunta.code) {
              let validarError = false;
              
              if (pregunta.required) {
                for (let i = 0; i < refsQuestions.current[index].childNodes[1].childNodes.length; i++) {
                  let value = refsQuestions.current[index].childNodes[1].childNodes[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0].value;
                  let ariaCurrentOtros = refsQuestions.current[index].childNodes[1].childNodes[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0].ariaCurrent;
                  if (value === "" && ariaCurrentOtros === null) {                 
                    var stateErrores = {
                      code: pregunta.code,
                      message: "Debe responder la pregunta",
                    }
                    errores.push(stateErrores);
                    setListaErrores(errores);
                    errorState = true;

                    return;
                  }
                }
              }

              let maximo = 0;
              let minimo = 0;
              for (let i = 0; i < refsQuestions.current[index].childNodes[1].childNodes.length; i++) {

                let max = Number(refsQuestions.current[index].childNodes[1].childNodes[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0]?.max);
                let min = Number(refsQuestions.current[index].childNodes[1].childNodes[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0]?.min);

                if (refsQuestions.current[index].childNodes[1].childNodes[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0].value !== "" &&
                  refsQuestions.current[index].childNodes[1].childNodes[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0].value !== undefined) {
                  let value = Number(refsQuestions.current[index].childNodes[1].childNodes[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0].value);
                  if (value > max || value < min) {
                    maximo = max;
                    minimo = min;
                    validarError = true;
                  }
                }
              }

              if (validarError) {
                var stateErrores = {
                  code: pregunta.code,
                  message: `Debe ingresar un valor entre ${minimo}, 2, 3, 4 ó ${maximo}`,
                }

                if (maximo > 5) {
                  stateErrores = {
                    code: pregunta.code,
                    message: `Debe ingresar un valor entre ${minimo}, 2, 3, ..., ${maximo}`,
                  }
                }
                errores.push(stateErrores);
                setListaErrores(errores);
                errorState = true;
              }

            }
            break;
        }
      }
    })

    if(errorState){
      Swal.fire({
        title: process.env.REACT_APP_MESSAGE_VALIDATION,
        icon: "warning",
        confirmButtonColor: "#008000"
      })
    }

    return errorState;
  }


  function onSubmit(e) {
    e.preventDefault();

    const PREG_TYPE_VAL_SIMPLE = "VAL_SIMPLE";
    const SEL_SIMP_CHECK = "SEL_SIMP_CHECK"
    const SEL_MULT_CHECK = "SEL_MULT_CHECK"
    const CATEGORIAS = "CATEGORIAS"
    const CATEGORIA_UNICA = "CATEGORIA_UNICA"
    const PRE_TYPE_DATE_TXT = "TXT"
    const NUMERIC = "NUMERIC"
    const DATE = "DATE"

    const errorState = validations(refsQuestions);
    if (errorState) return;

    //respuesta categorias
    objectRespuestas?.map((item) => {
      switch (item?.typeQuestion?.code) {
        case PREG_TYPE_VAL_SIMPLE:
          if (item.respuestaState || item.response?.respuesta) {
            let responseValue = "";
            if (item.respuesta === undefined && item.typeDate.code === "DATE") {
              responseValue = format(new Date(item.response?.respuesta + 'T00:00:00'), 'dd/MM/yyyy');
            } else {
              responseValue = item.respuesta !== undefined ? item.respuesta : item.response.respuesta;
            }

            const respuesta = {
              id: 0,
              respuesta: responseValue,
              codPregunta: item.code,
              opciones: [],
              categorias: []
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;
        case SEL_SIMP_CHECK:
          if (item.opcionesState || item.response?.opciones.length > 0) {
            let responseValue = item.opcionesRespuesta !== undefined ? item.opcionesRespuesta : item.response?.opciones;
            const opcionSelect = {
              keyRespuesta: responseValue[0]?.keyRespuesta !== undefined ? responseValue[0]?.keyRespuesta : responseValue[0]?.keyResponse,
              otro: ""
            }

            const respuesta = {
              id: 0,
              respuesta: "",
              codPregunta: item.code,
              opciones: [opcionSelect],
              categorias: []
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;

        case SEL_MULT_CHECK:
          if (item?.opcionesChekState || item.response?.opciones.length > 0) {
            let responseValue = item?.opcionesCheckRespuesta !== undefined ? item?.opcionesCheckRespuesta : item?.response.opciones;
            let respValue = item?.respuesta !== undefined ? item?.respuesta : item?.response?.respuesta;
            let opcionCheckMultiSelect = []
            responseValue?.map((option) => {
              let opcionCheckSelect = {
                keyRespuesta: option?.keyRespuesta !== undefined ? option?.keyRespuesta : option.keyResponse,
                checked: true
              }
              opcionCheckMultiSelect = [...opcionCheckMultiSelect, opcionCheckSelect]
            })

            const respuesta = {
              id: 0,
              respuesta: respValue !== "" || respValue !== undefined ? respValue : "",
              codPregunta: item.code,
              opciones: opcionCheckMultiSelect,
              categorias: []
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;

        case CATEGORIA_UNICA:
        case CATEGORIAS:
          if (item.categoriasState || item.response?.categorias?.length > 0) {
            let responseValue = item.categoriasRespuesta !== undefined ? item.categoriasRespuesta : item.response.categorias
            const respuesta = {
              id: 0,
              respuesta: "",
              codPregunta: item.code,
              opciones: [],
              categorias: responseValue
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;
      }
    });

    //enviar a la api
    dataRespuestas.codigo = dataEncuestas.data.code;
    dataRespuestas.respuestaPreguntas = arrayRespuestaPreguntas;

    //regisrar encuesta
    callAPIregister(
      { service: encuesta, type: "saveEncuestaCompleta" },
      dataRespuestas,
      null,
      dispatchEncuestas,
      SUCCESS,
      LISTA_ERRORS,
      !isSuccess,
      dispatch,
      () => { },
      () => { },
      () => { },
      setMessageSuccess,

    );

  }

  function onSubmitFloating(e) {
    e.preventDefault();

    const PREG_TYPE_VAL_SIMPLE = "VAL_SIMPLE";
    const SEL_SIMP_CHECK = "SEL_SIMP_CHECK"
    const SEL_MULT_CHECK = "SEL_MULT_CHECK"
    const CATEGORIAS = "CATEGORIAS"
    const CATEGORIA_UNICA = "CATEGORIA_UNICA"
    const PRE_TYPE_DATE_TXT = "TXT"
    const NUMERIC = "NUMERIC"
    const DATE = "DATE"

    objectRespuestas?.map((item) => {


      switch (item.typeQuestion.code) {
        case PREG_TYPE_VAL_SIMPLE:
          if (item.respuestaState || item.response?.respuesta) {
            let responseValue = item.respuesta !== undefined ? item.respuesta : item.response.respuesta;
            const respuesta = {
              id: 0,
              respuesta: responseValue,
              codPregunta: item.code,
              opciones: [],
              categorias: []
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;
        case SEL_SIMP_CHECK:
          if (item.opcionesState || item.response?.opciones.length > 0) {
            let responseValue = item.opcionesRespuesta !== undefined ? item.opcionesRespuesta : item.response?.opciones;
            //
            const opcionSelect = {
              keyRespuesta: responseValue[0]?.keyRespuesta !== undefined ? responseValue[0]?.keyRespuesta : responseValue[0]?.keyResponse,
              otro: ""
            }
            const respuesta = {
              id: 0,
              respuesta: "",
              codPregunta: item.code,
              opciones: [opcionSelect],
              categorias: []
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;

        case SEL_MULT_CHECK:
          if (item?.opcionesChekState || item.response?.opciones.length > 0) {
            let responseValue = item?.opcionesCheckRespuesta !== undefined ? item?.opcionesCheckRespuesta : item?.response.opciones;
            let respValue = item?.respuesta !== undefined ? item?.respuesta : item?.response?.respuesta;
            //
            let opcionCheckMultiSelect = []
            responseValue?.map((option) => {
              let opcionCheckSelect = {
                keyRespuesta: option?.keyRespuesta !== undefined ? option?.keyRespuesta : option.keyResponse,
                checked: true
              }
              opcionCheckMultiSelect = [...opcionCheckMultiSelect, opcionCheckSelect]
            })

            const respuesta = {
              id: 0,
              respuesta: respValue !== "" || respValue !== undefined ? respValue : "",
              codPregunta: item.code,
              opciones: opcionCheckMultiSelect,
              categorias: []
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;

        case CATEGORIA_UNICA:
        case CATEGORIAS:
          let respuestaOtrosCategoria = "";
          let responseRespuesta = item.response?.categorias.find(cat => cat.respuesta !== undefined)?.respuesta;
          if (item.respuestaState || responseRespuesta) {
            let respuesta = item.respuesta !== undefined ? item.respuesta : "";
            let response = responseRespuesta !== undefined ? responseRespuesta : "";

            respuestaOtrosCategoria = respuesta !== "" ? respuesta : response;
          }

          if (item.categoriasState || item.response?.categorias?.length > 0 || item.respuestaState || responseRespuesta) {
            let responseValue = item.categoriasRespuesta !== undefined ? item.categoriasRespuesta : item.response?.categorias
            const respuesta = {
              id: 0,
              respuesta: respuestaOtrosCategoria,
              codPregunta: item.code,
              opciones: [],
              categorias: responseValue !== undefined ? responseValue : []
            };
            arrayRespuestaPreguntas.push(respuesta);
          }
          break;
      }

    })

    //enviar a la api
    dataRespuestas.codigo = dataEncuestas.data.code;
    dataRespuestas.respuestaPreguntas = arrayRespuestaPreguntas;

    //regisrar encuesta

    callAPIregister(
      { service: encuesta, type: "saveEncuesta" },
      dataRespuestas,
      null,
      () => { },
      null,
      null,
      null,
      dispatch,
      () => { },
      () => { }
    );

  }

  return (
    <React.Fragment>
      {dataEncuestas.Code === 202 || dataEncuestas.Code === 404 ? <FormError message={dataEncuestas.Data.Message} />
        : isSuccess ? <FormSuccess message={messageSuccess} /> :
          <FormEncuestaPage
            dataEncuesta={dataEncuestas}
            onSubmit={onSubmit}
            onSubmitFloating={onSubmitFloating}
            handleChange={handleChange}      
            formValues={formValues}
            setFormValues={setFormValues}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            listaErros={listaErros}
            addToRefs={addToRefs}
            addToRefsRb={addToRefsRb}
            addToRefsQuestions={addToRefsQuestions}
            listaErrores={listaErrores}
            checkedRB={checkedRB}
            today={fechaActual}
          />
      }
    </React.Fragment>
  );
};

export default withSelect(withFilter(FormEncuesta));
