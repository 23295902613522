import TerminosCondiciones from './TerminosCondiciones';
import { authRoles } from 'app/auth';

const TerminosCondicionesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.visita,
  routes: [
    {
      path: '/terminos-y-condiciones',
      component: TerminosCondiciones,
    },
  ],
};

export default TerminosCondicionesConfig;