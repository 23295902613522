import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import '../FloatingButton.css'; // 

function FloatingButton({ onClick }) {
  const scrollToBottom = () => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }); // Mueve la página al final suavemente
  };

  return (
    <div className="floating-button-container">
      <button className="floating-button" title="Ir abajo" onClick={scrollToBottom} type="button"> 
        <ArrowDownwardIcon sx={{ fontSize: 30 }}/>
      </button>

      <button className="floating-button" title='Click para guardar la respuesta' onClick={onClick}>
      <SaveIcon sx={{ fontSize: 30 }}/>
      </button>
    </div>
  );
}

export default FloatingButton;

