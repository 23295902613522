import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

const EditPassword = ({ control }) => {

	const classes = useStyles();

	const [showPassword, setShowPassword] = useState(false);
	const [showRepeatPassword, setShowRepeatPassword] = useState(false);
	
	return (
		<div className={classes.root}>
			<Accordion>
				<AccordionSummary
					expandIcon={<Icon>expand_more_icon</Icon>}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>Cambiar Contraseña</Typography>
				</AccordionSummary>

				<AccordionDetails className="flex-col">
					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className="mb-10 w-full"
								label="Contraseña"
								type="password"
								variant="outlined"
								InputProps={{
									className: 'pr-2',
									type: showPassword ? 'text' : 'password',
									endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPassword(!showPassword)}>
											<Icon className="text-20" color="action">
												{showPassword ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
									),
								}}
							/>
						)}
					/>

					<Controller
						name="passwordRepeat"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className="mb-10 w-full"
								label="Repetir Contraseña"
								type="password"
								variant="outlined"
								InputProps={{
									className: 'pr-2',
									type: showRepeatPassword ? 'text' : 'password',
									endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
											<Icon className="text-20" color="action">
												{showRepeatPassword ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
									),
								}}
							/>
						)}
					/>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}

export default EditPassword;