import axios from "axios";
class Encuesta {
  //API

  getEncuestaLink({ code }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/encuestas/current${code}`)
        .then(({ data }) => {
          !data.error ? resolve(data) : reject(data.message);
        })
        .catch((response) => {
          if (response) return reject(response);
        });
    });
  }

  getEncuesta() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/encuestas/current`)
        .then(({data}) => {
          !data.error ? resolve(data) : reject(data.message);
        })
        .catch((response) => {
          if (response) return reject(response);
        });
    });
  }

  getEncuestaInfo({ code }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/encuestas/info${code}`)
        .then(({ data }) => {
          !data.error ? resolve(data) : reject(data.message);
        })
        .catch((response) => {
          if (response) return reject(response);
        });
    });
  }

  saveEncuesta(dataRespuestas) {
    return new Promise((resolve, reject) => {
      axios
        .post("/encuestas/respuestas/save", dataRespuestas)
        .then(({data}) => {
          !data.error ? resolve(data) : reject(data.message);
        })
        .catch((response) => {
          if (response) return reject(response);
        });
    })
  }

  saveEncuestaCompleta(dataRespuestas) {
    return new Promise((resolve, reject) => {
      axios
        .post("/encuestas/respuestas/save-complete", dataRespuestas)
        .then(({data}) => {
          !data.error ? resolve(data) : reject(data.message);
        })
        .catch((response) => {  
          if (response) return reject(response);
        });
    })
  }

}
export const encuesta = new Encuesta();