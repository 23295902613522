import React, { useState, useContext } from "react";

import Typography from "@material-ui/core/Typography";

import TablePagination from "../../layaut/tablePagination/TablePagination";
import { RolesContext } from "../context/RolesContext";
import { headCells } from "../utils/helper";
import Rows from "./Rows";
import { PAGE, ROWS_PER_PAGE } from "../types";
import AlertPage from "../../layaut/Alert";

const SeeChangePricePage = () => {
  const {
    stateRoles: { page, rowsPerPage, dataRoles, permits },
    dispatchRoles,
    handleOpenModal,
  } = useContext(RolesContext);

  return (
    <React.Fragment>
      {permits.toAccess ? (
        <React.Fragment>
          <div className="flex mb-40">
            <Typography variant="h5">Admin. Roles</Typography>
          </div>

          <TablePagination
            Component={Rows}
            data={dataRoles}
            dataFilter={{}}
            dispatch={dispatchRoles}
            deleteData={() => {}}
            headCells={headCells}
            handleOpenModal={handleOpenModal}
            page={page}
            permits={{}}
            rowsPerPage={rowsPerPage}
            TYPES={[ROWS_PER_PAGE, PAGE]}
            typeView="roles"
          />
        </React.Fragment>
      ) : (
        <AlertPage
          message="No tienes acceso a este formulario"
          severity="info"
        />
      )}
    </React.Fragment>
  );
};

export default SeeChangePricePage;
