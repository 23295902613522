import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth } from "./auth";
import history from "@history";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import BlockUiProvider from "./BlockUiContext";
import FuseLayout from "@fuse/core/FuseLayout";
import withAppProviders from "./withAppProviders";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

function App() {
	return (
		<Auth>
		<Router history={history}>
		  <FuseAuthorization>
			<FuseTheme>
			  <SnackbarProvider
				maxSnack={5}
				anchorOrigin={{
				  vertical: "bottom",
				  horizontal: "right",
				}}
				classes={{
				  containerRoot:
					"bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
				}}
			  >
				<BlockUiProvider>
				  <FuseLayout />
				</BlockUiProvider>
			  </SnackbarProvider>
			</FuseTheme>
		  </FuseAuthorization>
		</Router>
	  </Auth>
	);
}

export default withAppProviders(App)();
