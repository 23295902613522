import axios from "axios";
import { getValidationError } from "app/main/utils/validationError";

class User {
  getPermits() {
    return new Promise((resolve, reject) => {
      axios
        .get("")
        .then(({ data }) => {
          if (data.code === 422) return reject(data.data);

          !data.error ? resolve(data.data) : reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get("")
        .then(({ data }) => {
          if (!data.error) {
            const { data: dataRoles } = data;
            resolve(dataRoles);
          } else reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }

  getUsersPagination(total = 5, page = 1) {
    const query = `Descripcion=&Cantidad=${total}&Pagina=${page}`;

    return new Promise((resolve, reject) => {
      axios
        .get(``)
        .then(({ data }) => {
          if (!data.error) {
            data.data.length > 0 ? resolve(data.data) : resolve([-1]);
          } else reject(data.message);
        })
        .catch((response) => {
          if (!response) return reject(response);
        });
    });
  }
}

export const user = new User();
