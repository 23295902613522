import React from 'react'
import Grid from "@material-ui/core/Grid";
import "../styles/styleFormError.css";
import ErrorIcon from '@mui/icons-material/Error';

const FormError = ({message}) => (
    <Grid>
    <div className="container_error" >
        <div className="form_container_err">
        <Grid>
                <h1 className="from_heading_err font-bold" style={{ color: "#8A133D" }}></h1>
            </Grid>
            <Grid className='mt-24 ml-12'>
                <ErrorIcon className="icon-error" color="error"/>
            <span className='text_err'>{message}</span>
            </Grid>
        </div>
    </div>
</Grid>
)

export default FormError