import React from 'react';

import EnhancedTableHeadPage from './EnhancedTableHeadPage';

const EnhancedTableHead = (props) => {

	const { headCells, onSelectAllClick, numSelected, rowCount } = props;
	
	return (
		<EnhancedTableHeadPage
			headCells={headCells}
			numSelected={numSelected}
			onSelectAllClick={onSelectAllClick}
			rowCount={rowCount}
		/>
	)
}

export default EnhancedTableHead;