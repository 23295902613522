import React from 'react'
import FormTerminosCondiciones from '../container/FormTerminosCondiciones'

const TerminosCondicionesPage = () => {
    return (
        <React.Fragment>
            <FormTerminosCondiciones />
        </React.Fragment>
    )
}

export default TerminosCondicionesPage