import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { HomeStyle } from "./style";

const useStyles = makeStyles({
  layoutRoot: {},
  left: {
    marginLeft: "73px",
  },
});

const Home = () => {
  const classes = useStyles();

  return <HomeStyle>Bienvenido</HomeStyle>;
};

export default Home;

// <FusePageSimple
// 	classes={{
// 		root: classes.layoutRoot,
// 	}}
// 	className={classes.left}
// 	header={
// 		<div className="p-24">
// 			<h4>Header</h4>
// 		</div>
// 	}
// 	contentToolbar={
// 		<div className="px-24">
// 			<h4>Content Toolbar</h4>
// 		</div>
// 	}
// 	content={
// 		<div className="p-24">
// 			<h4>Content</h4>
// 			<br />
// 			<DemoContent />
// 		</div>
// 	}
// />
