import React, { useState, useEffect, useContext } from "react";

import { useAxios } from "../customHooks/useAxios";
import EncuestasPage from "./components/EncuestasPage";
import FormInterrogation from "./components/FormInterrogation";
import FormAgradecimiento from "./components/FormAgradecimiento";
import EncuestasProvider from "./context/EncuestasContext";
import FormError from "./components/FormError";
import FormSuccess from "./components/FormSuccess";
const Encuestas = () => {
  return (
    <EncuestasProvider>
        <EncuestasPage />
    </EncuestasProvider>
  );
};

export default Encuestas;