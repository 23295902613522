import React, {useContext} from "react";
import FormEncuesta from "../container/FormEncuesta";
import "../style.css";

const EncuestasPage = () => {
	return (
		<React.Fragment>
			<FormEncuesta /> 
		</React.Fragment>
	);
};
export default EncuestasPage;
