import React from 'react'
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { FormHelperText } from '@material-ui/core';

const TableCategorias = ({
    description,
    typeDate,
    configCategoria,
    question,
    register,
    errors,
    handleChange,
    addToRefsQuestions,
}) => {
    return (
        <div className="">
            <Box display="flex" justifyContent="center" alignItems="center">
                <TableContainer sx={{ width: "100%", marginTop: "2rem" }}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="simple table"
                        id={question?.code}
                        ref={addToRefsQuestions}
                    >
                        <TableHead>
                            <TableRow>
                                {typeDate === "CAT_MULTI_OPT" ? description ? <TableCell scope="col"><span style={{ fontSize: 15 }}>{description}</span></TableCell>
                                    : <TableCell scope="col"></TableCell>
                                    : null
                                }

                                {
                                    question?.configCategoria?.secciones?.map(seccion =>
                                        <TableCell key={seccion.id} scope="col"><span style={{ fontSize: 15 }}>{seccion.titulo}</span></TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {question?.configCategoria?.categorias?.map(categoria =>
                                <TableRow key={categoria.id} >
                                    {
                                        typeDate === "CAT_MULTI_OPT" ?
                                            <TableCell>
                                                {
                                                    categoria?.otros === true ?
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            label={categoria?.title}
                                                            defaultValue={question?.response?.categorias?.find(cat => cat.id == categoria.id)?.respuesta}
                                                            onChange={(e) => { handleChange(e, null, null, null, categoria) }}
                                                            className='mb-3'
                                                            InputLabelProps={{ style: { fontSize: 15 } }}
                                                            inputProps={
                                                                {   
                                                                    maxLength: question?.length > 0 ? question?.length : 9999999999,
                                                                    min: question?.min,
                                                                    max: question?.max > 0 ? question?.max : 9999999999,
                                                                    step: 1,
                                                                    style: { fontSize: 15 }
                                                                }
                                                            }
                                                        />
                                                        : <span style={{ fontSize: 15 }}>{categoria?.title}</span>
                                                }
                                            </TableCell>
                                            : null
                                    }

                                    {categoria?.secciones?.map(seccion =>
                                        <TableCell key={seccion.question.id}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label={seccion.question.label}
                                                defaultValue={question?.response?.categorias?.find(cat => cat.id == categoria.id)?.secciones?.find(sec => sec.id == seccion.id)?.respuesta}
                                                type={seccion?.question?.typeDate.code === "NUMERIC" ? "number"
                                                    : seccion?.question?.typeDate.code === "DATE" ? "date"
                                                        : "text"}
                                                onKeyDown={(e) => {                                              
                                                    if (seccion?.question?.typeDate.code === "NUMERIC" && (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+")) {
                                                        e.preventDefault()
                                                    }
                                                }}
                                                onChange={(e) => { handleChange(e, null, null, seccion, categoria) }} //
                                                InputLabelProps={{ style: { fontSize: 15 } }}
                                                required={seccion?.question?.required}
                                                inputProps={{
                                                    'aria-current': categoria.otros === true ? 'otros' : null,
                                                    maxLength: seccion.question?.length > 0 ? seccion.question?.length : 9999999999,
                                                    min: seccion.question.min > 0 ? seccion.question.min : 0,
                                                    max: seccion.question.max > 0 ? seccion.question.max : 9999999999,
                                                    step: 1,
                                                    style: { fontSize: 15 },
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <span style={{ fontSize: 15 }}>{seccion?.question.label}</span>
                                                        </InputAdornment>
                                                    ),
                                                }}

                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default TableCategorias