import { showMessage } from "app/store/fuse/messageSlice";

export function callAPI(
  service,
  typeService,
  argument1,
  argument2,
  dispatch,
  TYPE,
  dispatchRedux,
  setIsBlock = () => { },
  handleCloseModal = () => { },
) {
  service[typeService](argument1, argument2) 
    .then((resolve) => {
      if (Array.isArray(resolve)) {
        resolve.length === 0
          ? dispatchRedux(
            showMessage({
              message: "No se encontro data",
              autoHideDuration: 5000000000000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "info",
            })
          )
          : dispatch({
            type: TYPE,
            payload: resolve,
          });
      } else {
        dispatch({
          type: TYPE,
          payload: resolve,
        });
      }

      setIsBlock(false);
    })
    .catch((err) => {
      dispatchRedux(
        showMessage({
          message: err.message || err, //text or html
          autoHideDuration: 5000000000000,
          anchorOrigin: {
            vertical: "top", //top bottom
            horizontal: "center", //left center right
          },
          variant: err.status === 401 ? "warning" : "error", //success error info warning null
        })
      );

      dispatch({
        type: TYPE,
        payload: [-1],
      });

      setIsBlock(false);
      handleCloseModal();
    });
}

export function callAPIregister(
  { service, type },
  argument1,
  argument2,
  dispatch,
  TYPE,
  TYPE1,
  payload,
  dispatchRedux,
  handleCloseModal,
  setIsBlock = () => { },
  setSaveData = () => { },
  setMessageSuccess = () => { },

) {
  service[type](argument1, argument2)
    .then((resp) => {
      if (resp)
       if(argument1.noAutorizado === undefined){
        dispatchRedux(
          showMessage({
            message: resp.Message, //text or html
            autoHideDuration: 5000000000000,
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "success", //success error info warning null
          })
        );
       }
        
      dispatch({
        type: TYPE,
        payload,
      });
      setMessageSuccess(resp.Message);
      typeof resp !== "string" && setSaveData(resp.data);

      handleCloseModal();
      setIsBlock(false);


    })
    .catch((err = []) => {
      if (err.length > 1 && typeof err !== "string") {
        dispatchRedux(
          showMessage({
            message: (
              <span>
                {err.map((li, index) => (
                  <li key={index}>{li}</li>
                ))}
              </span>
            ), //text or html
            autoHideDuration: 5000000000000,
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: "error", //success error info warning null
          })
        )
       

      } else {
        dispatchRedux(
          showMessage({
            message: err?.response.data.Message !== undefined ? err?.response.data.Message : err?.response.data.Data.Message, //text or html
            autoHideDuration: 5000000000000,
            anchorOrigin: {
              vertical: "top", //top bottom
              horizontal: "center", //left center right
            },
            variant: err.response.status === 401 || 
            err.response.status === 409 || 
            err.response.status === "ERR_NETWORK" ? "warning" : "error", //success error info warning null
          })
        );
        
        //guardar la lista de validaciones
        if (err.response.status === 400) {
          dispatch({
            type: TYPE1,
            payload: err.response.data.Data,
          });
        }
      }

      setIsBlock(false);
    });
}

export function callAPIdelete(
  service,
  id,
  dispatch,
  TYPE,
  payload,
  dispatchRedux,
  handleCloseModal,
  setIsBlock = () => { }
) {
  service(id)
    .then((resp) => {
      dispatchRedux(
        showMessage({
          message: resp, //text or html
          autoHideDuration: 5000000000000,
          anchorOrigin: {
            vertical: "top", //top bottom
            horizontal: "center", //left center right
          },
          variant: "success", //success error info warning null
        })
      );

      dispatch({ type: TYPE, payload });
      if (handleCloseModal != null) handleCloseModal();
      setIsBlock(false);
    })
    .catch((err) => {
      dispatchRedux(
        showMessage({
          message: err.message || err, //text or html
          autoHideDuration: 5000000000000,
          anchorOrigin: {
            vertical: "top", //top bottom
            horizontal: "center", //left center right
          },
          variant: err.status === 401 ? "warning" : "error", //success error info warning null
        })
      );

      setIsBlock(false);
    });
}
