import React from 'react';

const colors = {
	error: '#F44336',
	warning: '#ED6C02',
	info: 'white',
	// info: '#0288D1',
	success: '#2E7D32',
}

const AlertPage = ({ message, severity }) => (
	<div
		className="flex items-center justify-center text-white text-sm font-bold px-4 py-16 my-20 rounded-lg"
		role="alert"
		style={{
			backgroundColor: colors[severity],
			color: 'black',
		}}
	>
		<p>{message}.</p>
	</div>
)

export default AlertPage;