import styled from "styled-components";

export const InputStyle = styled.input`
  &:focus-visible {
    outline: none;
  }
`;
//#f2f2f2
export const MainStyle = styled.main`
  min-height: 100vh;
  padding: 0px;
  background-color: #ffffff;

  .tab {
    background-color: white;
    border-radius: 20px 20px 0 0px;
  }

  @media (max-width: 452px) {
    padding: 90px 20px 0 70px;
  }
`;
