import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import TwoButtons from "./TwoButtons";
import CloseModal from "./CloseModal";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

import { AlertDialogStyle } from "./style";
//import { OPEN_DIALOG_ALERT } from "../users/types";

const listActions = ["AnularVenta"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = ({
  aceptar = "aceptar",
  cancelar = "cancelar",
  colorCancel,
  dispatch,
  modalDelete,
  open,
  title = "Está seguro dar de baja al registro?",
  action = "",
  handleChangeGlosa,
  glosaValue,
  setGlosaValue = () => {},
}) => {
  const handleClose = () => {
    //dispatch({ type: OPEN_DIALOG_ALERT, payload: false });
  };

  useEffect(() => {
    setGlosaValue("");
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ zIndex: 1400 }}
    >
      <AlertDialogStyle className="p-20">
        <CloseModal handleClick={modalDelete} />

        <Paper elevation={3}>
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          {listActions.includes(action) ? (
            <DialogContent>
              <TextField
                variant="filled"
                autoFocus
                onChange={handleChangeGlosa}
                label="Glosa"
                value={glosaValue}
                multiline
                minRows={2}
                maxRows={4}
                fullWidth
              />
            </DialogContent>
          ) : null}
          <DialogActions>
            <TwoButtons
              text1={aceptar}
              text2={cancelar}
              classes1="mr-10"
              handleClick={modalDelete}
              colorCancel={colorCancel}
            />
          </DialogActions>
        </Paper>
      </AlertDialogStyle>
    </Dialog>
  );
};

export default AlertDialog;
