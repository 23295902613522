import React from "react";
import { Controller } from "react-hook-form";
import { styled } from '@mui/material/styles';
import TextField from "@material-ui/core/TextField";
import ModalForm from "../../layaut/ModalForm";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { FacebookLoginButton } from "react-social-login-buttons";
import { LoginSocialFacebook } from "reactjs-social-login";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

const RegisterUserPage = ({
    classes,
    control,
    errors,
    handleCloseModal,
    handleSubmit,
    isOpenModal,
    onSubmit,
    showPassword,
    countIntentosValue,
    onChangeRecaptcha,
    responseGoogle,
    responseFacebook,
    showMessageReCaptcha,
    setEmail
}) => (
    <ModalForm
        errors={errors}
        handleCloseModal={handleCloseModal}
        isOpen={isOpenModal}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        title={"Registrarme"}
        updateModalHeight={[]}
        updateDataEdit={{}}
        isViewButton={false}
    >
        <div className=" flex">
            <Grid className="mt-10">
                <Controller
                    name="names"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            type="text"
                            className="w-96"
                            label="Nombres"
                            variant="filled"
                            value={value}
                            onChange={onChange}
                            error={!!errors.names}
                            helperText={errors?.names?.message}
                            InputLabelProps={{
                                style: { fontSize: 15 },
                            }}
                            InputProps={{
                                style: { fontSize: 15, backgroundColor: "#ffff" },
                            }}
                            size="small"
                        />
                    )}
                />
            </Grid>

            <Grid className="mt-10 ml-16">
                <Controller
                    name="lastNames"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            type="text"
                            className="w-96"
                            label="Apellidos"
                            variant="filled"
                            value={value}
                            onChange={onChange}
                            error={!!errors.lastNames}
                            helperText={errors?.lastNames?.message}
                            InputLabelProps={{
                                style: { fontSize: 15, },
                            }}
                            InputProps={{
                                style: { fontSize: 15, backgroundColor: "#ffff" },
                            }}
                            size="small"
                        />
                    )}
                />
            </Grid>
        </div>
        <Grid className="mt-0">
            <BootstrapTooltip title={
                <>
                    <p style={{ fontSize: '9px' }}><strong>Nombre de Usuario</strong></p>
                    <p style={{ fontSize: '9px' }}><em>El nombre de usuario que elijas debe seguir el siguiente formato. Por favor, ten en cuenta lo siguiente:</em></p>

                    <ol>
                        <li style={{ fontSize: '9px' }}>&nbsp;El nombre de usuario puede contener los siguientes caracteres: letras may&uacute;sculas, letras min&uacute;sculas, n&uacute;meros, as&iacute; como los caracteres especiales &quot;_&quot; y &quot;.&quot;.</li>
                        <li style={{ fontSize: '9px' }}>No se permiten espacios en blanco u otros caracteres especiales.</li>
                        <li style={{ fontSize: '9px' }}>&nbsp;Aseg&uacute;rate de que tu nombre de usuario sea &uacute;nico y f&aacute;cil de recordar.</li>
                    </ol>
                </>
                }>
                <IconButton>
                    <HelpOutlineIcon />
                </IconButton>
            </BootstrapTooltip>
            <Controller
                name="username"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        type="text"
                        label="Username"
                        variant="filled"
                        fullWidth
                        value={value}
                        onChange={e => {
                            onChange(e);
                            setEmail(e.target.value)
                        }}
                        error={!!errors.username}
                        helperText={errors?.username?.message}
                        InputLabelProps={{
                            style: { fontSize: 15, },
                        }}
                        InputProps={{
                            style: { fontSize: 15, backgroundColor: "#ffff" },
                        }}
                        size="small"
                    />
                )}
            />
        </Grid>

        <Grid className="mt-10">
            <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        type="text"
                        fullWidth
                        value={value}
                        error={!!errors.email}
                        onChange={e => {
                            onChange(e);
                            setEmail(e.target.value)
                        }}
                        helperText={errors?.email?.message}
                        InputLabelProps={{
                            style: { fontSize: 15 },
                        }}
                        InputProps={{
                            style: { fontSize: 15, backgroundColor: "#ffff" },
                        }}
                        label="Email"
                        variant="filled"
                        size="small"
                    />
                )}

            />
        </Grid>

        <Grid className="mt-10">
            <Controller
                name="password"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="Contraseña"
                        type="password"
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        variant="filled"
                        size="small"
                        InputLabelProps={{
                            style: { fontSize: 15 },
                        }}
                        InputProps={{
                            type: showPassword ? "text" : "password",
                            style: { fontSize: 15, backgroundColor: "#ffff" },
                        }}
                        required
                    />
                )}
            />
        </Grid>

        <p className="textCuenta mt-2" style={{ color: "#8A133D" }}>Tengo cuenta</p>
        {countIntentosValue > 2 ? (
            <div className="w-96 ml-40">
                <ReCAPTCHA
                    sitekey="6Lf1Q8QnAAAAANiLHOFaXyh-B4ZdJd-VTQ7ZOXJl"
                    onChange={onChangeRecaptcha}
                />
            </div>) : null}

        {countIntentosValue > 2 ? (
            <p className="ml-40" style={{ color: "#ff0000" }}>{showMessageReCaptcha}</p>
        ) : null}


        <Grid className="ml-32 ">
            <Button
                type="submit"
                variant="contained"
                aria-label="LOG IN"
                className="btn_register"
                value="legacy"
                size="large"
                onClick={onSubmit}
            >
                Registrar
            </Button>
        </Grid>
        <div className="border-2 border-black-200 w-full mt-10" style={{ backgroundColor: "#8A133D" }}></div>
        <p className="textCuenta mt-10" style={{ color: "#8A133D" }}>Registrarme con</p>
        <Grid>
            <GoogleOAuthProvider
                clientId="664102857392-8deeqtvmspnvusv029ob8f07gbbk63fo.apps.googleusercontent.com">
                <GoogleLogin
                    onSuccess={responseGoogle}
                />
            </GoogleOAuthProvider>
        </Grid>

        <Grid className="mt-3 w-1/2">
            <LoginSocialFacebook
                appId="145297411906310"
                onResolve={responseFacebook}
            >
                <FacebookLoginButton>
                    <span>Iniciar con Facebook</span>
                </FacebookLoginButton>
            </LoginSocialFacebook>

        </Grid>
    </ModalForm>
);

export default RegisterUserPage;
